
interface onResultDataResponse {
    state: string
    city: string
    neighborhood: string
    street: string
}

async function get(cepString: string, onResult: (data : onResultDataResponse) => void, onError: (error : any) => void) {

	function getAFetchServiceUrl(cepToFetch : string) {
		const randomInt = Math.random()

		// if (randomInt > 0.5) {	
		// }

		return `https://viacep.com.br/ws/${cepToFetch}/json/`
	}

	try {
		const validCepRegex = /^[0-9]{5}-[0-9]{3}$/

		if (!validCepRegex.test(cepString)) return null

		const serviceApiUrl = getAFetchServiceUrl(cepString)

		const response = await fetch(serviceApiUrl)
		const responseData = await response.json()

		const data = {
			state: responseData.state || responseData.uf || '',
			city: responseData.city || responseData.localidade || '',
			neighborhood: responseData.district || responseData.bairro || '',
			street: responseData.address || responseData.logradouro || '',
		}

		if (onResult) {
			return onResult(data)
		}
		return data
	} catch (error) {
		if (onError) {
			return onError(error)
		}
		return error
	}
}

const CepService = {
	get,
}

export { CepService }

