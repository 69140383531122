
import { initializeApp, getApps, getApp } from 'firebase/app'

import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"


const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
}

const firebaseApp = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp()

const auth = getAuth(firebaseApp)
const firestoreDatabase = getFirestore(firebaseApp)
const firebaseStorage = getStorage(firebaseApp)


export {
	firebaseApp, auth, firestoreDatabase, firebaseStorage,
}

