
import { TextFieldProps } from '@mui/material/TextField'
import { IMaskMixin } from 'react-imask';

import { TextInput } from '../TextInput';
import './styles.scss'

interface TextFieldInterface {
    id: string;
    label?: string;
    name: string;
    value: any;
    onChange?: any;
    error?: any;
    helperText?: any;
    mask?: string;
    definitions?: {
        [key: string]: RegExp
    }
}

export const TextInputMask = IMaskMixin(({ id, name, label, value, onChange, error, mask, definitions, helperText, ...rest }: TextFieldProps & TextFieldInterface ) => {
    
    return <TextInput 
                fullWidth
                id={id}
                name={name}
                label={label}
                value={value}
                onChange={onChange}
                error={error}
                helperText={helperText}
                className="textInputChildren"
                {...rest}
            />;
});
