
import { Checkbox, FormControlLabel, FormLabel } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { memo, useEffect, useState } from 'react';
import { Storage } from '../../core/Controllers/Storage';
import { useAuth } from '../../hooks/useAuth';

import './styles.scss'

interface TextFieldInterface {
    label?: string;
    name?: string;
    value?: any;
    onChange?: any;
    error?: any;
    helperText?: any;
    disabled?: any;
}

export const InputFile = memo(function InputFile({ label, name, value, onChange, error, helperText, disabled }: TextFieldInterface & TextFieldProps) {

    const [fileData, setFileData] = useState('')
	const userContext = useAuth()


    async function handleChange(event: any) {

        if (!userContext?.user?.uid) return 
        
        const files = event.target.files
        const selectedFile = Array.from(files)[0] as any

        if (!selectedFile) return

        setFileData('carregando...')

        try {
            const path = `${userContext.user.uid}/${name}`
            await Storage().uploadFile(selectedFile, path)
            setFileData('Envio completo.')
            onChange({
                target: {
                    value: selectedFile.name,
                    id: name
                }
            })
            setTimeout(() => {
                setFileData('')
            }, 2000);
        } catch (error) {
            setFileData('Houve um erro, tente novamente!')
        }
    }

    const htmlElementId = 'fileInputId' + '_' + name

    // console.log('aaaa', label, name, value)

    return (
        <>
            <label htmlFor={htmlElementId} className={'fileInputContainer' + (error ? ' error': '')}>
                {value && <label htmlFor={htmlElementId} className='fileLabelTitle'>{label}</label>}
                
                <label
                    htmlFor={htmlElementId}
                    className="fileLabel"
                >
                    {value ? value : label}
                </label>

                <label htmlFor={htmlElementId} className='fileButton'>UPLOAD</label>

                <input
                    id={htmlElementId}
                    className="pictureInput"
                    type="file"
                    accept=".pdf"
                    onChange={handleChange}
                    style={{ display: 'none' }}
                    disabled={disabled}
                />
            </label>

            {fileData && <p>{fileData}</p>}
            {helperText && <p className='errorHelperText'>{helperText}</p>}
        </>
    )

})

