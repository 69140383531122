import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module'
import { App } from './routes';

import './index.css';

const tagManagerArgs = {
    gtmId: 'GTM-N4F5FB3'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
