
import { useNavigate, } from 'react-router-dom'

import './styles.scss'
import { Button } from '../../components/Button'
import { baseColors } from '../../styles'
import { routesNames } from '../../routes/routesNames'
import { borderRadius } from '@mui/system'
import { User } from '../../core/Controllers/User'
// import { firestoreDatabase } from '../../services/firebase'
// import { addDoc, collection } from 'firebase/firestore'

export function ThankYouIndication() {

    const navigate = useNavigate();

    const UserLogOut = async function () {
        await User().logout();
        return window.location.href = '/login';
      };
    // async function handleClick() {
    //     await addDoc(collection(firestoreDatabase, 'mail'), {
    //         to: ['jonas.henrique@lapisraro.com.br', 'gilberto.junior@lapisraro.com.br', 'carolz@lapisraro.com.br'],
    //         message: {
    //             subject: 'Boas vindas da CBMM!',
    //             text: 'Boas vindas em plaintext.',
    //             html: 'Boas vindas em <code>HTML</code>.',
    //         }
    //     })
    // }

    return (
        <div >
            <div className='thankYouIndication rectangle'>
                <h2 className='titleThankYouIndication'>
                    {routesNames.thankYouIndication.pageTitle}
                </h2>
                <h3 className='titleThankYouIndication'>
                    {routesNames.thankYouIndication.pageSubTitle}
                </h3>

                {/* <h3 className='textThankYou'>
                    {routesNames.thankYouIndication.pageSubTitle}
                </h3> */}

                <h4 className='subTextThankYouIndication'>
                    {routesNames.thankYouIndication.pageH1}
                </h4>
                <h4 className='subTextThankYouIndication'>
                    {routesNames.thankYouIndication.pageH2}
                </h4>

                <br />

                <Button
                    style={{
                        cursor: 'pointer',
                        padding: '10px 150px 10px 150px',
                        fontWeight: 800,
                        fontSize: 16,
                        fontFamily: "Open Sans",
                        textAlign: 'center',
                        width: '304px',
                        height: '45px',
                    }}

                    label="FINALIZAR"
                    color={baseColors.lightIndigo} 
                    onClick={() => UserLogOut()}/>
                <br />
            </div>
            <div className='divBotao'>
                <Button
                    style={{
                        cursor: 'pointer',
                        color: baseColors.clean, 
                        padding: '10px 40px 10px 40px',
                        fontWeight: 800,
                        fontSize: 16,
                        fontFamily: "Open Sans",
                        textAlign: 'center',
                        border: '3px solid #80CAFF',
                        border_borderRadius: '41px ',
                        width: '376px',
                        height: '45px',
                    }}

                    label="VOLTAR E EDITAR SUA INDICAÇÃO"
                  
                    color={baseColors.white} 
                    onClick={() => navigate(-1)}/>
            </div>        
        </div>
    )
}

