
import { Checkbox, FormControlLabel, FormLabel } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { memo, useEffect, useState } from 'react';

import './styles.scss'

interface TextFieldInterface {
    id: string;
    label?: string;
    name: string;
    value: any;
    onChange?: any;
    error: any;
    helperText: any;
    checkboxLabel?: string;
}

export const InputWithCheckbox = memo(function InputWithCheckbox({ id, name, label, checkboxLabel, value, onChange, error, helperText, ...rest }: TextFieldInterface & TextFieldProps) {

    const [fieldValue, setFieldValue] = useState<{ value: string, checked: boolean }>({ value: value, checked: false })

    function handleChange(textValue: string) {
        setFieldValue({ value: textValue, checked: false })
        onChange({
            target: {
                value: textValue,
                id: id
            }
        })
    }

    function handleCheck(checked: boolean) {
        if (!checkboxLabel) return null

        if (checked) {
            setFieldValue({ value: '', checked: true })
            onChange({
                target: {
                    value: checkboxLabel,
                    id: id
                }
            })
        } else {
            setFieldValue({ value: checkboxLabel, checked: false })
            onChange({
                target: {
                    value: fieldValue.value,
                    id: id
                }
            })
        }
    }


    useEffect(() => {
        if (!checkboxLabel) return 

        if (value === checkboxLabel) {
            setFieldValue({
                value: checkboxLabel,
                checked: true
            })
            onChange({
                target: {
                    value: checkboxLabel,
                    id: id
                }
            })
        }

    }, [value, checkboxLabel, id, onChange])

    
    useEffect(() => {
        if (!checkboxLabel) return 

        if (value !== fieldValue.value) {
            setFieldValue( old => ({ ...old, value: value }))
            onChange({
                target: {
                    value: value,
                    id: id
                }
            })
        }

    }, [value, checkboxLabel, id, onChange, fieldValue.value])


    if (!checkboxLabel) return null


    return <>
        <div className='InputWithCheckbox'>

            <div style={{width: '69%'}}>
                <FormLabel>{label}</FormLabel>
                <TextField
                    fullWidth
                    id={id}
                    name={name}
                    value={fieldValue.value}
                    onChange={(e) => handleChange(e.target.value)}
                    error={error}
                    className="textInput"
                    disabled={fieldValue.checked}
                    variant="outlined"
                    {...rest}
                />
            </div>

            <FormControlLabel
                style={{marginTop: label ? 20 : 0}}
                control={
                    <Checkbox
                        checked={fieldValue.checked}
                        onChange={(e) => handleCheck(e.target.checked)}
                        inputProps={{ 'aria-label': checkboxLabel }}
                    />
                }
                label={checkboxLabel}
            />

        </div>

        {helperText && <p className='errorHelperText'>{helperText}</p>}
    </>

})
