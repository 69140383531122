import { createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { doc, serverTimestamp, getDoc, updateDoc, query, where, getDocs, collection, deleteDoc, setDoc, limit, Timestamp } from "firebase/firestore";

import { auth, firestoreDatabase } from '../../services/firebase'

// import { Storage } from './Storage';

const UsersCollection = 'users'

const IndicationsCollection = 'indications'


interface createUserData {
    email: string;
    password: string;
}

interface updateUserData {
    uid: string;
    newPhoto: string;
}


export function User() {

    async function create({ email, password }: createUserData) {
        try {
            const userCreated = await createUserWithEmailAndPassword(auth, email, password)

            const startUserData = {
                completed: false,
            }

            await setDoc(doc(firestoreDatabase, UsersCollection, userCreated.user.uid), {
                uid: userCreated.user.uid,
                email: userCreated.user.email, ...startUserData,
                type: 'user',
                createdAt: serverTimestamp(), updatedAt: serverTimestamp(), loginAt: serverTimestamp(),
            })

            return {
                uid: userCreated.user.uid,
                email: userCreated.user.email,
                type: 'user',
                password, ...startUserData,
            }
        } catch (error: any) {
            // console.log('ERROR on User - create: ', error, error.message)
            throw new Error(error)
        }
    }

    async function getUsersDataByEmail(email: string) {
        try {

            const docsSnap = await getDocs(
                query(
                    collection(firestoreDatabase, UsersCollection),
                    where('email', '==', email),
                )
            )

            const data = docsSnap.docs.map(doc => doc.data().type)
            return data;

        } catch (error) {
            console.log('ERROR on User - getUsersFromCompanyId: ', error)
            return 'Error!'
        }
    }

    async function getData(userUid: string) {
        try {
            const docSnap = await getDoc(doc(firestoreDatabase, UsersCollection, userUid))
            if (docSnap.exists()) {
                return { ...docSnap.data(), uid: docSnap.id }
            } else {
                return {}
            }
        } catch (error) {
            // console.log('ERROR on User - getData: ', error)
            return 'Error!'
        }
    }

    async function getDataFromEmail(email: string) {
        try {

            const docsSnap = await getDocs(
                query(
                    collection(firestoreDatabase, 'pastRegistrationData'),
                    where('email', '==', email),
                    limit(1)
                )
            )

            const data = docsSnap.docs.map(doc => ({ ...doc.data() }))

            return data
        } catch (error) {
            // console.log('ERROR on User - getUsersFromCompanyId: ', error)
            return 'Error!'
        }
    }

    async function getAllUsersFinalReport() {
        console.log("getAllUsersFinalReport")

        try {
            const docsSnap = await getDocs(
                query(
                    collection(firestoreDatabase, UsersCollection)
                )
            )

            const data = [] as any

            docsSnap.docs.sort((a, b) => a.data().createdAt?.seconds - b.data().createdAt?.seconds).forEach(doc => {

                const userData = doc.data()
                if (!userData.completed) return

                const dateString = (item: any) => typeof item === 'number'
                    ? new Date(item).toISOString().replace('T', ' ').substring(0, new Date(item).toISOString().length - 5)
                    : new Date(item.seconds * 1000).toISOString().replace('T', ' ').substring(0, new Date(item.seconds * 1000).toISOString().length - 5)

                data.push({
                    ...userData,
                    loginAt: dateString(userData.loginAt),
                    createdAt: dateString(userData.createdAt),
                })
            })

            const techUses = data.filter((user: any) => user.category === 'Tecnologia')
            const CienceUses = data.filter((user: any) => user.category === 'Ciência')

            console.log(JSON.stringify(techUses))
            //console.log(JSON.stringify(CienceUses))

        } catch (error) {
            // console.log('ERROR on User - getUsersFromCompanyId: ', error)
            return 'Error!'
        }
    }

    async function getAllUserDataForReport() {
        try {

            const docsSnap = await getDocs(
                query(
                    collection(firestoreDatabase, UsersCollection)
                )
            )

            const data = docsSnap.docs.sort((a, b) => (a.data().createdAt?.seconds || a.data().createdAt) - (b.data().createdAt?.seconds || b.data().createdAt)).map(doc => {

                const userData = doc.data()

                // Email	Nome	Catgoria	Última etapa enviada	Terminou?	Data último login	Data de cadastro

                const lastStep = userData.category === 'Tecnologia' ? (
                    userData.additionalInformation1 ? 'Informações adicionais' :
                        userData.technologicalContributions4 ? 'Contribuições tecnológicas parte 2' :
                            userData.technologicalContributions3 ? 'Contribuições tecnológicas' :
                                userData.technologyPersonalData ? 'Dados pessoais' : 'Cadastro inicial'
                ) : (
                    userData.extraData1 ? 'Dados adicionais' :
                        userData.scientificProduction1 ? 'Produção científica' :
                            userData.bibliometricInformation1 ? 'dados Bibliométricos' :
                                userData.evidenceOfRecognition1 ? 'Evidências de reconhecimento' :
                                    userData.sciencePersonalData ? 'Dados pessoais' : 'Cadastro inicial'
                )

                const dateString = (item: any) => typeof item === 'number'
                    ? new Date(item).toISOString().replace('T', ' ').substring(0, new Date(item).toISOString().length - 5)
                    : new Date(item.seconds * 1000).toISOString().replace('T', ' ').substring(0, new Date(item.seconds * 1000).toISOString().length - 5)

                return {
                    email: userData.email,
                    name: userData.name,
                    enframe: userData.enframe,
                    category: userData.category,
                    lastStep: userData.completed ? 'Revisão' : lastStep,
                    completed: userData.completed ? 'Sim' : 'Não',
                    loginAt: dateString(userData.loginAt),
                    createdAt: dateString(userData.createdAt),
                }
            })

            return data
        } catch (error) {
            // console.log('ERROR on User - getUsersFromCompanyId: ', error)
            return []
        }
    }

    async function exportAllIndicationToCSV() {

        try {
            const docsSnap = await getDocs(
                query(
                    collection(firestoreDatabase, IndicationsCollection),
                    // where('enframe', '==', true),
                )


            )

            const docsSnap1 = await getDocs(
                query(
                    collection(firestoreDatabase, 'institution'),
                    // where('enframe', '==', true),
                )
            )


            console.log('total', docsSnap.docs.length)



            // Ordenar dados seguindo ordem anterior
            // retornar array de arrays
            // Converter matriz para CSV
            const completeCsvData = []



            completeCsvData.push([
                "Nome",
                "Instituição",
                "Indicado",
                "Email",
                "Telefone",
                "Categoria",
            ])

            docsSnap1.docs.forEach(doc => {
                const institutionData = doc.data()



                docsSnap.docs.forEach(doc => {

                    const indicationsData = doc.data()
             
                    if (institutionData.uid === indicationsData.institutionId){
                        
                    
                        if(indicationsData.completed){



                    /* userData.additionalInformation ? 'Informações adicionais' : 
                    userData.technologicalContributions2 ? 'Contribuições tecnológicas parte 2' :
                    userData.technologicalContributions ? 'Contribuições tecnológicas' :
                    userData.technologyPersonalData ? 'Dados pessoais' : 'Cadastro inicial' */

                    let updatedAtDate: any;

                    console.log(institutionData)

                    if (indicationsData.updatedAt.nanoseconds) {

                        updatedAtDate = new Date(indicationsData.updatedAt.seconds * 1000 + indicationsData.updatedAt.nanoseconds / 1000000)

                    } else {
                        updatedAtDate = new Date(indicationsData.updatedAt);
                    }

                    let newItemArray: any[] = [];

                    if (updatedAtDate.getFullYear() === 2024) {

                        newItemArray = [
                            institutionData.name,
                            institutionData.institutionName,                          
                            indicationsData.name_1,
                            indicationsData.email_1,
                            indicationsData.phoneNumber_1,
                            indicationsData.category_1,
                            indicationsData.name_2,
                            indicationsData.email_2,
                            indicationsData.phoneNumber_2,
                            indicationsData.category_2,
                            indicationsData.name_3,
                            indicationsData.email_3,
                            indicationsData.phoneNumber_3,
                            indicationsData.category_3,
                            indicationsData.name_4,
                            indicationsData.email_4,
                            indicationsData.phoneNumber_4,
                            indicationsData.category_4,
                            indicationsData.name_5,
                            indicationsData.email_5,
                            indicationsData.phoneNumber_5,
                            indicationsData.category_5,
                            indicationsData.name_6,
                            indicationsData.email_6,
                            indicationsData.phoneNumber_6,
                            indicationsData.category_6,
                            indicationsData.name_7,
                            indicationsData.email_7,
                            indicationsData.phoneNumber_7,
                            indicationsData.category_7,
                            indicationsData.name_8,
                            indicationsData.email_8,
                            indicationsData.phoneNumber_8,
                            indicationsData.category_8,
                            indicationsData.name_9,
                            indicationsData.email_9,
                            indicationsData.phoneNumber_9,
                            indicationsData.category_9,
                            indicationsData.name_10,
                            indicationsData.email_10,
                            indicationsData.phoneNumber_10,
                            indicationsData.category_10,

                        ]

                    }

                    const newArray = newItemArray.map(item => typeof item === 'string' ? item.replaceAll(';', '. ') : item)

                    completeCsvData.push(newArray)
                }
                }
                })
            })



            // return completeCsvData.map(row =>
            //     row.map(String)  // convert every value to String
            //         .map(v => v.replaceAll('"', '""'))  // escape double colons
            //         .map(v => `""${v}""`)  // quote it
            //         .join(';')  // comma-separated
            //   ).join('\r\n');  // rows starting on new lines

            return completeCsvData.map(row => {
                const filteredRow = row.filter(item => item !== null && item !== undefined && item !== '');
                if (filteredRow.length > 0) {
                    return filteredRow.reduce((acc, item) => {
                        const innerValue = item.toString();
                        const result = innerValue.replace(/"/g, '""');
                        const finalText = result.replace(/\\/g, "\\\\").replace(/\n/g, "\\n");
                        return acc += '"' + finalText + '";';
                    }, '');
                } else {
                    return null; // Retorna nulo para linhas vazias
                }
            }).filter(line => line !== null) // Remove linhas vazias
                .join('\r\n');


        } catch (error) {
            console.log('ERROR on User - exportAllIndicationToCSV: ', error)
            return 'Error!'
        }
    }


    async function exportAllUsersToCSV(category: string) {

        try {
            const docsSnap = await getDocs(
                query(
                    collection(firestoreDatabase, UsersCollection),
                    // where('enframe', '==', true),
                    where('category', '==', category),

                )
            )


            console.log('total', docsSnap.docs.length)

            // Ordenar dados seguindo ordem anterior
            // retornar array de arrays
            // Converter matriz para CSV
            const completeCsvData = []

            if (category === 'Tecnologia') {

                completeCsvData.push([
                    "Nome",
                    "Como você ficou sabendo do Prêmio CBMM?",
                    "O que te motivou a participar?",
                    "E-mail",
                    "Celular",
                    "Estado",
                    "Cidade",
                    "Título da candidatura",
                    "Cotitulares da candidatura em grupo",
                    "RG",
                    "CPF",

                    "O grau de inovação introduzido",
                    "A relevância de tais contribuições, destacando os impactos econômicos, sociais ou ambientais. Nota: O item requer a comprovação inequívoca das contribuições.",
                    "Apresente evidências do reconhecimento (por terceiros) da significância das contribuições tecnológicas",
                    "Aponte a forma de reconhecimento da qualificação e contribuições do candidato pela manifestação por lideranças empresariais, administradores de órgãos públicos, outras instituições privadas e governamentais, comprovação de reconhecimento internacional e outras manifestações pertinentes",

                    "Quantas empresas de tecnologia foram criadas ou ajudou a criar?",
                    "Lista das empresas de tecnologia criadas ou que ajudou a criar. Nota: O item requer a comprovação inequívoca das empresas criadas",
                    "Apresente demonstração de empresas que se beneficiaram de suas contribuições tecnológicas",
                    "Liste as patentes concedidas pelo INPI ou agências estrangeiras",
                    "Quantas patentes foram licenciadas",
                    "Descreva o licenciamento de patentes a empresas no país e no exterior, destacando os impactos econômicos. Nota: O item requer a comprovação inequívoca dos impactos econômicos",
                    "Descreva o desenvolvimento de produtos e processos que não foram patenteados",
                    "Indique os produtos desenvolvidos que estão no mercado",
                    "Indique processos tecnológicos desenvolvidos que estão em execução por empresas",

                    "Inclua cargos ocupados relativos às atuações em sistemas tecnológicos",
                    "Inclua consultoria a empresas e organizações tecnológicas",
                    "Descreva os principais prêmios descrevendo a importância de cada um",
                    "Descreva sua atuação na formação de recursos humanos que produziram impactos em inovação",
                    "Descreva a sua familiaridade no uso e aplicação de tecnologias modernas (exemplos: transformação digital, inteligência artificial, nanotecnologia, internet das coisas, entre outros)",
                    "Descreva suas publicações técnico-científicas mais relevantes (textos, relatórios industriais, etc.)",
                    "Outras informações relevantes",
                ])





                docsSnap.docs.forEach(doc => {
                    const userData = doc.data()



                    /* userData.additionalInformation ? 'Informações adicionais' : 
                    userData.technologicalContributions2 ? 'Contribuições tecnológicas parte 2' :
                    userData.technologicalContributions ? 'Contribuições tecnológicas' :
                    userData.technologyPersonalData ? 'Dados pessoais' : 'Cadastro inicial' */

                    let updatedAtDate: any;



                    if (userData.updatedAt.nanoseconds) {

                        updatedAtDate = new Date(userData.updatedAt.seconds * 1000 + userData.updatedAt.nanoseconds / 1000000)

                    } else {
                        updatedAtDate = new Date(userData.updatedAt);
                    }

                    let newItemArray: any[] = [];

                    if (updatedAtDate.getFullYear() === 2024) {

                        newItemArray = [
                            userData.name,
                            userData?.personalData?.howDidYouFindOutAboutUs,
                            userData?.personalData?.motivationToParticipate,
                            userData.email,
                            userData.phoneNumber,
                            userData?.personalData?.state,
                            userData?.personalData?.city,
                            userData?.personalData?.applicationTitle,
                            userData?.personalData?.applicationCoTitle,
                            userData?.personalData?.rg,
                            userData?.personalData?.cpf,

                            userData?.technologicalContributions3?.degreeOfInnovation || '',
                            userData?.technologicalContributions3?.relevanceOfContributions || '',
                            userData?.technologicalContributions3?.evidenceOfRecognition || '',
                            userData?.technologicalContributions3?.formOfRecognition || '',

                            userData?.technologicalContributions4?.technologyCompaniesCreated || '',
                            userData?.technologicalContributions4?.listOfTechnologyCompaniesCreated || '',
                            userData?.technologicalContributions4?.demonstrationOfCompaniesBenefited || '',
                            userData?.technologicalContributions4?.patentsGrantedByINPI || '',
                            userData?.technologicalContributions4?.patentsLicensed || '',
                            userData?.technologicalContributions4?.licensingOfPatentsToCompanies || '',
                            userData?.technologicalContributions4?.developmentOfProductsAndProcessesBeenPatented || '',
                            userData?.technologicalContributions4?.developedProductsOnMarket || '',
                            userData?.technologicalContributions4?.developedTechnologicalProcessesExecutedByCompanies || '',

                            userData?.additionalInformation1?.activitiesInTechnologicalSystems || '',
                            userData?.additionalInformation1?.consultancyToTechnologyCompanies || '',
                            userData?.additionalInformation1?.mainAwardsWithImportance || '',
                            userData?.additionalInformation1?.trainingHumanResourcesImpactedInnovation || '',
                            userData?.additionalInformation1?.familiarityWithModernTechnologies || '',
                            userData?.additionalInformation1?.mostRelevantTechnicalScientificPublication || '',
                            userData?.additionalInformation1?.otherRelevantInformation || '',
                        ]

                    }




                    const newArray = newItemArray.map(item => typeof item === 'string' ? item.replaceAll(';', '. ') : item)

                    completeCsvData.push(newArray)
                })

            } else {

                completeCsvData.push([
                    "Nome",
                    "Como você ficou sabendo do Prêmio CBMM?",
                    "O que te motivou a participar?",
                    "E-mail",
                    "Celular",
                    "Estado",
                    "Cidade",
                    "Título da candidatura",
                    "Cotitulares da candidatura em grupo",
                    "RG",
                    "CPF",

                    'Liste os 10 artigos de maior importância na sua área de pesquisa pulicado em revistas:',
                    'Liste os seus 10 artigos mais citados e os respectivos números de citações:',
                    'Liste os capítulos de livros e artigos publicados a convite de editores de revistas:',
                    'Liste os livros publicados:',

                    'Liste as patentes concedidas pelo INPI e instituições estrangeiras:',
                    'Liste as academias de Ciência nas quais é membro:',
                    'Liste seus prêmios nacionais e internacionais mais relevantes, descrevendo brevemente a importância de cada um:',
                    'Liste suas plenary e keynote talks em eventos no Brasil e no exterior:',
                    'Liste as posições atuais e anteriores na editoria de revistas científicas:',
                    'Liste os congressos nacionais e internacionais organizados como chairman:',
                    'Liste as posições de liderança atuais e passadas em sociedades e instituições científicas do Brasil e internacionais:',

                    'Número de artigos científicos publicados em periódicos com peer review indexados pelo Scopus:',
                    'Tem quantas citações (Scopus)?',
                    'Quantos alunos de iniciação científica, mestrado, doutorado e pós-doutorado já orientou?',
                    'Qual é o seu H-index (Scopus)? Compare com seus pares do Brasil e exterior.',
                    'Qual é o seu Leadership Index (LI)? LI é a razão entre o número de artigos nos quais o seu H-index é maior que dos demais coautores e o número total de (seus) artigos em periódicos indexados pelo Scopus (LI {"<="}1).',

                    'Descreva outras informações relevantes:',
                ])

                docsSnap.docs.forEach(doc => {
                    const userData = doc.data()

                    /* userData.extraData ? 'Dados adicionais' : 
                    userData.scientificProduction ? 'Produção científica' :
                    userData.bibliometricInformation ? 'dados Bibliométricos' :
                    userData.evidenceOfRecognition ? 'Evidências de reconhecimento' :
                    userData.sciencePersonalData ? 'Dados pessoais' : 'Cadastro inicial' */

                    let updatedAtDate: any;



                    if (userData.updatedAt.nanoseconds) {

                        updatedAtDate = new Date(userData.updatedAt.seconds * 1000 + userData.updatedAt.nanoseconds / 1000000)

                    } else {
                        updatedAtDate = new Date(userData.updatedAt);
                    }

                    if (updatedAtDate.getFullYear() === 2024) {

                        completeCsvData.push([
                            userData.name,
                            userData?.personalData?.howDidYouFindOutAboutUs,
                            userData?.personalData?.motivationToParticipate,
                            userData.email,
                            userData.phoneNumber,
                            userData?.personalData?.state,
                            userData?.personalData?.city,
                            userData?.personalData?.applicationTitle,
                            userData?.personalData?.applicationCoTitle,
                            userData?.personalData?.rg,
                            userData?.personalData?.cpf,

                            userData?.scientificProduction1?.mostImportantArticlesInYourResearch || '',
                            userData?.scientificProduction1?.mostCitedArticlesAndCitationNumbers || '',
                            userData?.scientificProduction1?.publishedBookChaptersAndArticles || '',
                            userData?.scientificProduction1?.listPublishedBooks || '',
                            userData?.scientificProduction1?.patentsGrantedByINPI || '',

                            userData?.evidenceOfRecognition1?.scienceAcademiesWhichYouAreMember || '',
                            userData?.evidenceOfRecognition1?.listOfMostRelevantAwardsWithDescription || '',
                            userData?.evidenceOfRecognition1?.listPlenaryAndKeynoteTalks || '',
                            userData?.evidenceOfRecognition1?.listCurrentPositionsEditorshipInJournals || '',
                            userData?.evidenceOfRecognition1?.listCongressesOrganizesAsChairman || '',
                            userData?.evidenceOfRecognition1?.listLeadershipPositionsScientificSocieties || '',

                            userData?.bibliometricInformation1?.scientificArticlesPublishedIndexedByScopus || '',
                            userData?.bibliometricInformation1?.totalCitations || '',
                            userData?.bibliometricInformation1?.totalScientificInitiationStudentsSupervised || '',
                            userData?.bibliometricInformation1?.hIndexScopusScore || '',
                            userData?.bibliometricInformation1?.leadershipIndex || '',

                            userData?.extraData1?.otherRelevantInformation || '',
                        ])

                    }

                })

            }

            // return completeCsvData.map(row =>
            //     row.map(String)  // convert every value to String
            //         .map(v => v.replaceAll('"', '""'))  // escape double colons
            //         .map(v => `""${v}""`)  // quote it
            //         .join(';')  // comma-separated
            //   ).join('\r\n');  // rows starting on new lines

            return completeCsvData.map(row => row.reduce((acc, item) => {
                // acc += '"' + item.replace(/"/g, '""') + '";'
                const innerValue = !item ? '' : item.toString();
                const result = innerValue.replace(/"/g, '""');
                const finalText = result.replace(/\\/g, "\\\\")
                    .replace(/\n/g, "\\n")
                return acc += '"' + finalText + '";';

            }, '')).join(' \r\n')

        } catch (error) {
            console.log('ERROR on User - exportAllUsersToCSV: ', error)
            return 'Error!'
        }
    }


    async function getAllInstitutionsDataForReport() {
        try {

            const docsSnap = await getDocs(
                query(
                    collection(firestoreDatabase, 'institution')
                )
            )

            const data = docsSnap.docs.sort((a, b) => (a.data().createdAt?.seconds || a.data().createdAt) - (b.data().createdAt?.seconds || b.data().createdAt)).map(doc => {

                const userData = doc.data()

                // Nome
                // Email
                // categoria
                // Telefone
                // completo
                // Enquadramento

                // row.name
                // row.email
                // row.category
                // row.phoneNumber
                // row.completed
                // row.enframe

                const dateString = (item: any) => typeof item === 'number'
                    ? new Date(item).toISOString().replace('T', ' ').substring(0, new Date(item).toISOString().length - 5)
                    : new Date(item.seconds * 1000).toISOString().replace('T', ' ').substring(0, new Date(item.seconds * 1000).toISOString().length - 5)

                return {
                    institutionName: userData.institutionName,
                    cnpj: userData.cnpj,
                    name: userData.name,
                    email: userData.email,
                    completed: userData.completed ? 'Sim' : 'Não',
                    loginAt: dateString(userData.loginAt),
                    createdAt: dateString(userData.createdAt),
                }
            })

            return data
        } catch (error) {
            // console.log('ERROR on User - getUsersFromCompanyId: ', error)
            return 'Error!'
        }
    }

    async function getAllIndicationsDataForReport() {
        try {

            const docsSnap = await getDocs(
                query(
                    collection(firestoreDatabase, 'indications')
                )
            )

            const data = docsSnap.docs.sort((a, b) => (a.data().createdAt?.seconds || a.data().createdAt) - (b.data().createdAt?.seconds || b.data().createdAt)).map(doc => {

                const userData = doc.data()

                const separator = doc.id
                const index = separator.split('_')[1]

                // Nome
                // Email
                // categoria
                // Telefone
                // completo
                // Enquadramento

                // name_1
                // email_1
                // institutionId
                // phoneNumber_1
                // cep_1
                // state_1
                // city_1
                // address_1
                // addressComplement_1

                const dateString = (item: any) => typeof item === 'number'
                    ? new Date(item).toISOString().replace('T', ' ').substring(0, new Date(item).toISOString().length - 5)
                    : new Date(item.seconds * 1000).toISOString().replace('T', ' ').substring(0, new Date(item.seconds * 1000).toISOString().length - 5)

                return {
                    name: userData[`name_${index}`],
                    email: userData[`email_${index}`],
                    institutionId: userData.institutionId,
                    phoneNumber: userData[`phoneNumber_${index}`],
                    cep: userData[`cep_${index}`],
                    state: userData[`state_${index}`],
                    city: userData[`city_${index}`],
                    address: userData[`address_${index}`],
                    addressComplement: userData[`addressComplement_${index}`],
                    loginAt: dateString(userData.loginAt),
                    createdAt: dateString(userData.createdAt),
                }
            }).filter(item => !!item.email)

            return data
        } catch (error) {
            // console.log('ERROR on User - getUsersFromCompanyId: ', error)
            return 'Error!'
        }
    }


    async function login(email: string, password: string) {
        try {
            const resp = await signInWithEmailAndPassword(auth, email, password)
            return resp.user.uid
        } catch (error: any) {
            // console.log('ERROR on User - login: ', error)
            throw new Error(error)
        }
    }

    async function logout() {
        try {
            return await signOut(auth)
        } catch (error) {
            // console.log('ERROR on User - logout: ', error)
            return 'Error!'
        }
    }

    async function update({ uid, ...rest }: updateUserData) {
        if (!uid) return null

        if (!uid.trim()) {
            return null
        }

        try {
            const dataToUpdate = { ...rest } as any

            await updateDoc(doc(firestoreDatabase, UsersCollection, uid), {
                ...dataToUpdate, updatedAt: serverTimestamp()
            })

            return {
                ...rest, ...dataToUpdate, updatedAt: new Date().getTime(), uid
            }
        } catch (error: any) {
            // console.log('ERROR on User - update: ', error, error.message)
            throw new Error(error)
        }
    }

    async function sendPasswordReset(email: string) {
        await sendPasswordResetEmail(auth, email)
    }

    async function sendConfirmEmail() {
        if (auth.currentUser) {
            await new Promise<any>((resolve, reject) => { setTimeout(resolve, 400) })
            await sendEmailVerification(auth.currentUser)
        }
    }

    return {
        update, getData, create, login, logout, sendPasswordReset, getDataFromEmail, sendConfirmEmail, getAllUserDataForReport, getAllInstitutionsDataForReport, getAllIndicationsDataForReport, getAllUsersFinalReport, exportAllUsersToCSV, getUsersDataByEmail, exportAllIndicationToCSV
    }
}
