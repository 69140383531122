
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    createRoutesFromElements,
} from "react-router-dom";

import { routesNames } from "./routesNames";

import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { Root } from "../pages/Root";
import ErrorPage from "../pages/ErrorPage";
import { FormPageLayout } from "../layouts/FormPageLayout";
import { PersonalData } from "../pages/Tecnology/PersonalData";
import { TechnologicalContributions } from "../pages/Tecnology/TechnologicalContributions";
import { AdditionalInformation } from "../pages/Tecnology/AdditionalInformation";
import { TechnologicalContributions2 } from "../pages/Tecnology/TechnologicalContributions2";
import { Revision } from "../pages/Tecnology/Revision";
import { AuthContextProvider } from "../Contexts/Auth";
import { ThankYou } from "../pages/ThankYou";
import { ThankYouIndication } from "../pages/ThankYouIndication";
import { PageLayout } from "../layouts/PageLayout";
import { ExtraData } from "../pages/Science/ExtraData";
import { BibliometricInformation } from "../pages/Science/BibliometricInformation";
import { EvidenceOfRecognition } from "../pages/Science/EvidenceOfRecognition";
import { ScientificProduction } from "../pages/Science/ScientificProduction";
import { SciencePersonalData } from "../pages/Science/SciencePersonalData";
import { ScienceRevision } from "../pages/Science/ScienceRevision";
import { ConfirmEmail } from "../pages/ConfirmEmail";
import { PreviousRegistrationData } from "../pages/PreviousRegistrationData";
import { Indication } from "../pages/Indication";
import { InstitutionIndication } from "../pages/InstitutionIndication";
import { LoginInstitution } from "../pages/LoginInstitution";
import { LoginAdmin } from "../pages/LoginAdmin";
import { AdminPage } from "../pages/Admin";
import { AdminInstitution } from "../pages/AdminInstitution"; 
import { AdminInsc2024 } from "../pages/AdminInsc2024";
import { AdminInsc10052024 } from "../pages/AdminInsc10052024"; 
import { AdminInsti2024 } from "../pages/AdminInsti2024";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Root />} errorElement={<ErrorPage />} >
            
            <Route element={
                <PageLayout />
            }>
                <Route path={routesNames.home.path} element={<Home />} />
                {/* <Route path={routesNames.login.path} element={<Login />} /> */}

                <Route path={routesNames.indication.path} element={<Indication />} />
                {/* <Route path={routesNames.indicationLogin.path} element={<LoginInstitution />} /> */}
                <Route path={routesNames.InstitutionIndication.path} element={<InstitutionIndication />} />


                <Route path={routesNames.thankYou.path} element={<ThankYou />} />
                <Route path={routesNames.thankYouIndication.path} element={<ThankYouIndication />} />
                <Route path={routesNames.confirmEmail.path} element={<ConfirmEmail />} />
                <Route path={routesNames.previousRegistrationData.path} element={<PreviousRegistrationData />} />
                
                <Route path={routesNames.loginAdmin.path} element={<LoginAdmin />} />
                <Route path={routesNames.adminPage.path} element={<AdminPage />} />
                <Route path={routesNames.adminInstitution.path} element={<AdminInstitution />} />
                <Route path={routesNames.AdminInsc10052024.path} element={<AdminInsc10052024 />} /> 
                <Route path={routesNames.AdminInsc2024.path} element={<AdminInsc2024 />} /> 
                <Route path={routesNames.AdminInsti2024.path} element={<AdminInsti2024 />} /> 
                

                <Route element={
                    <FormPageLayout headerProgress={true} type="Tecnologia" />
                }>
                    
                    <Route path={routesNames.technologyPersonalData.path} element={<PersonalData />} />
                    <Route path={routesNames.technologicalContributions.path} element={<TechnologicalContributions />} />
                    <Route path={routesNames.technologicalContributions2.path} element={<TechnologicalContributions2 />} />
                    <Route path={routesNames.additionalInformation.path} element={<AdditionalInformation />} />
                    <Route path={routesNames.technologyRevision.path} element={<Revision />} />
                </Route>

                <Route element={
                    <FormPageLayout headerProgress={true} type="Ciência" />
                }>
                    <Route path={routesNames.sciencePersonalData.path} element={<SciencePersonalData />} />
                    <Route path={routesNames.scientificProduction.path} element={<ScientificProduction />} />
                    <Route path={routesNames.evidenceOfRecognition.path} element={<EvidenceOfRecognition />} />
                    <Route path={routesNames.bibliometricInformation.path} element={<BibliometricInformation />} />
                    <Route path={routesNames.extraData.path} element={<ExtraData />} />
                    <Route path={routesNames.scienceRevision.path} element={<ScienceRevision />} />
                </Route>
            </Route>

        </Route>
    )
);


export function App() {
    return (
        <AuthContextProvider>
            <RouterProvider router={router} />
        </AuthContextProvider>
    )
}
