import { Link } from 'react-router-dom'
import logoImg from '../../assets/logo.png'
import { routesNames } from '../../routes/routesNames'
import { useEffect, useState } from 'react'


import './styles.scss'

const dictProgress = {
    'home': -1,
    'login': -1,
    'personalData': 0,
    'technologicalContributions': 1,
    'technologicalContributions2': 2,
    'additionalInformation': 3,

    'sciencePersonalData': 0,
    'scientificProduction': 1,
    'evidenceOfRecognition': 2,
    'bibliometricInformation': 3,
    'extraData': 4,
} as {
    [key: string]: number
}


export function ProgressHeader({ page, title, id, headerProgress, type }: { id: string, page: string, title: string, headerProgress?: boolean, type: 'Ciência' | 'Tecnologia' }, eventClick: boolean) {

    const progress = dictProgress[id];

    const routes = type === 'Ciência'
        ? Object.values(routesNames).filter(route => route.path.includes('/ciencia/') && !route.path.includes('/revisao'))
        : Object.values(routesNames).filter(route => route.path.includes('/tecnologia/') && !route.path.includes('/revisao'));

    const barWidth = 720 - routes.length * (type === 'Ciência' ? 18 : 24);
    const barMarginLeft = routes.length * 10;
    const progressBarWidth = barWidth * progress / (routes.length - 1);

 
    console.log(progress);

    return (
        <nav className="progressHeader" >

            <div className="circleContainer">

                <div className="circleRow" style={{ width: barWidth, marginLeft: barMarginLeft }}></div>
                <div className="circleRow progress" style={{ width: progressBarWidth, marginLeft: barMarginLeft }}></div>

                {routes.map((routeData, index) => (
                    <Link to={routeData?.path} key={`${routeData?.path}_${index}`} style={ progress < index ? {pointerEvents: 'none'} : {}  }>
                        <div className="circleBox" key={`${routeData?.path}_${index}`}>
                            <div className={`circle ${index > progress ? 'completed' : ''}${index === progress ? ' act' : ''}`}>
                                <span className="circleNumber" style={{ textAlign: 'center', color: '#fff', zIndex: 1 }}>{index + 1}</span>
                            </div>
                            <span className='text' >{routeData?.pageH1}</span>
                        </div>
                    </Link>
                ))}
            </div>

            <h1>{title}</h1>

        </nav>
    );
}