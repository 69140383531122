import { ButtonProps, FormLabel } from "@mui/material"
import { TextInput } from "../TextInput"
import {
    getStorage,
    ref,
    getDownloadURL,
} from "firebase/storage";
import './styles.scss'
import { useState } from "react"
import { useAuth } from "../../hooks/useAuth";

export function ButtonDownload({ onClick }: ButtonProps) {


    return (
        <button
            className='btn-Download'
            onClick={onClick && onClick}
        >
            Download
        </button>
    )
}

export function ViewRegisterData2024({ data }: { data: any }) {



    // const downloadFolderAsZip = async () => {



    //     var JSZip = require("jszip");
    //     const storage = getStorage();
    //     const folderRef = ref(
    //         storage,
    //         'uploads/' + data.uid 
    //     );
    //     const folder = await listAll(folderRef);
    //     const promises = folder.items
    //         .map(async (item) => {
    //             const file = await getMetadata(item);
    //             const fileRef = ref(storage, item.fullPath);
    //             const fileBlob = await getDownloadURL(fileRef).then((url) => {
    //                 return fetch(url).then((response) => response.blob());
    //             });
    //             JSZip.file(file.name, fileBlob);
    //         })
    //         .reduce((acc, curr) => acc.then(() => curr), Promise.resolve());
    //     await promises;
    //     const blob = await JSZip.generateAsync({ type: 'blob' });
    //     saveAs('download.zip');
    // };



    const storage = getStorage();



    // Create a reference under which you want to list
    const listRefcurriculum = ref(storage, 'uploads/' + data.uid + '/curriculum');
    const [url, setUrl] = useState('');


    // let firstDownload;
    getDownloadURL(listRefcurriculum)
        .then((res) => {
            console.log(res);

            setUrl(res);

        }).catch((error) => {
            console.log(error);
        });



    const listRefrecommendationLetters = ref(storage, 'uploads/' + data.uid + '/recommendationLetters.0');
    const [url1, setUrl1] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters)
        .then((res) => {
            console.log(res);

            setUrl1(res);

        }).catch((error) => {
            console.log(error);
        });




    const listRefrecommendationLettersOnly = ref(storage, 'uploads/' + 'yNgOccxvUiXQRsBY9ytcBUPN01P2' + '/recommendationLetters.3.pdf');
    const [urlOnly, setUrlOnly] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLettersOnly)
        .then((res) => {
            console.log(res);

            setUrlOnly(res);

        }).catch((error) => {
            console.log(error);
        });

    const listRefrecommendationLettersOnly1 = ref(storage, 'uploads/' + 'yNgOccxvUiXQRsBY9ytcBUPN01P2' + '/recommendationLetters.4.pdf');
    const [urlOnly1, setUrlOnly1] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLettersOnly1)
        .then((res) => {
            console.log(res);

            setUrlOnly1(res);

        }).catch((error) => {
            console.log(error);
        });




    const listRefrecommendationLetters1 = ref(storage, 'uploads/' + 'PZk2LT6HzycHRyWrfkFbOBrpCpa2' + '/AngeloPlastino sobre ConstantinoTsallis.pdf');
    const [url2, setUrl2] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters1)
        .then((res) => {
            console.log(res);

            setUrl2(res);

        }).catch((error) => {
            console.log(error);
        });

    const listRefrecommendationLetters2 = ref(storage, 'uploads/' + 'PZk2LT6HzycHRyWrfkFbOBrpCpa2' + '/CesarSaBarreto sobre ConstantinoTsallis.pdf');
    const [url3, setUrl3] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters2)
        .then((res) => {
            console.log(res);

            setUrl3(res);

        }).catch((error) => {
            console.log(error);
        });

    const listRefrecommendationLetters3 = ref(storage, 'uploads/' + 'PZk2LT6HzycHRyWrfkFbOBrpCpa2' + '/ChristianBeck sobre ConstantinoTsallis.pdf');
    const [url4, setUrl4] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters3)
        .then((res) => {
            console.log(res);

            setUrl4(res);

        }).catch((error) => {
            console.log(error);
        });


    const listRefrecommendationLetters4 = ref(storage, 'uploads/' + 'PZk2LT6HzycHRyWrfkFbOBrpCpa2' + '/Currículo do Sistema de Currículos Lattes (Constantino Tsallis).pdf');
    const [url5, setUrl5] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters4)
        .then((res) => {
            console.log(res);

            setUrl5(res);

        }).catch((error) => {
            console.log(error);
        });


    const listRefrecommendationLetters5 = ref(storage, 'uploads/' + 'PZk2LT6HzycHRyWrfkFbOBrpCpa2' + '/ErogluBoghosianBorgesTirnakli2024.pdf');
    const [url6, setUrl6] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters5)
        .then((res) => {
            console.log(res);

            setUrl6(res);

        }).catch((error) => {
            console.log(error);
        });


    const listRefrecommendationLetters6 = ref(storage, 'uploads/' + 'PZk2LT6HzycHRyWrfkFbOBrpCpa2' + '/HenrikJensen sobre ConstantinoTsallis.pdf');
    const [url7, setUrl7] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters6)
        .then((res) => {
            console.log(res);

            setUrl7(res);

        }).catch((error) => {
            console.log(error);
        });

    const listRefrecommendationLetters7 = ref(storage, 'uploads/' + 'PZk2LT6HzycHRyWrfkFbOBrpCpa2' + '/JersonLimaSilva sobre ConstantinoTsallis.pdf');
    const [url8, setUrl8] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters7)
        .then((res) => {
            console.log(res);

            setUrl8(res);

        }).catch((error) => {
            console.log(error);
        });

    const listRefrecommendationLetters8 = ref(storage, 'uploads/' + 'PZk2LT6HzycHRyWrfkFbOBrpCpa2' + '/MarcioAlbuquerque sobre ConstantinoTsallis.pdf');
    const [url9, setUrl9] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters8)
        .then((res) => {
            console.log(res);

            setUrl9(res);

        }).catch((error) => {
            console.log(error);
        });

    const listRefrecommendationLetters13 = ref(storage, 'uploads/' + 'PZk2LT6HzycHRyWrfkFbOBrpCpa2' + '/RicardoGalvao sobre ConstantinoTsallis.pdf');
    const [url20, setUr20] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters13)
        .then((res) => {
            console.log(res);

            setUr20(res);

        }).catch((error) => {
            console.log(error);
        });







    const listRefrecommendationLetters14 = ref(storage, 'uploads/' + 'wWkQkTgxduVaPGrPS7ESSQ8kRl12' + '/recommendationLetters.1');
    const [url21, setUr21] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters14)
        .then((res) => {
            console.log(res);

            setUr21(res);

        }).catch((error) => {
            console.log(error);
        });

    const listRefrecommendationLetters15 = ref(storage, 'uploads/' + 'wWkQkTgxduVaPGrPS7ESSQ8kRl12' + '/recommendationLetters.2');
    const [url22, setUr22] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters15)
        .then((res) => {
            console.log(res);

            setUr22(res);

        }).catch((error) => {
            console.log(error);
        });

    const listRefrecommendationLetters16 = ref(storage, 'uploads/' + 'Lzk1YqNLhdWjTBlofnanqwJHGbX2' + '/Indicacao_Hernane_assinado (2).pdf');
    const [url23, setUr23] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters16)
        .then((res) => {
            console.log(res);

            setUr23(res);

        }).catch((error) => {
            console.log(error);
        });

    const listRefrecommendationLetters17 = ref(storage, 'uploads/' + 'Lzk1YqNLhdWjTBlofnanqwJHGbX2' + '/recommendationLetters.1');
    const [url24, setUr24] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters17)
        .then((res) => {
            console.log(res);

            setUr24(res);

        }).catch((error) => {
            console.log(error);
        });

    const listRefrecommendationLetters18 = ref(storage, 'uploads/' + 'wWkQkTgxduVaPGrPS7ESSQ8kRl12' + '/recommendationLetters.5');
    const [url25, setUr25] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters18)
        .then((res) => {
            console.log(res);

            setUr25(res);

        }).catch((error) => {
            console.log(error);
        });

    const listRefrecommendationLetters19 = ref(storage, 'uploads/' + 'wWkQkTgxduVaPGrPS7ESSQ8kRl12' + '/recommendationLetters.6');
    const [url26, setUr26] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters19)
        .then((res) => {
            console.log(res);

            setUr26(res);

        }).catch((error) => {
            console.log(error);
        });

    const listRefrecommendationLetters20 = ref(storage, 'uploads/' + 'wWkQkTgxduVaPGrPS7ESSQ8kRl12' + '/recommendationLetters.7');
    const [url27, setUr27] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters20)
        .then((res) => {
            console.log(res);

            setUr27(res);

        }).catch((error) => {
            console.log(error);
        });

    const listRefrecommendationLetters21 = ref(storage, 'uploads/' + 'Lzk1YqNLhdWjTBlofnanqwJHGbX2' + '/recommendationLetters.2');
    const [url28, setUr28] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters21)
        .then((res) => {
            console.log(res);

            setUr28(res);

        }).catch((error) => {
            console.log(error);
        });


    const listRefrecommendationLetters22 = ref(storage, 'uploads/' + 'cdn1GFtBAJRCopyDlzSGM1wbsPt1' + '/cartas_Dachamir_Hotza.pdf');
    const [url29, setUr29] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters22)
        .then((res) => {
            console.log(res);

            setUr29(res);

        }).catch((error) => {
            console.log(error);
        });



    const listRefrecommendationLetters9 = ref(storage, 'uploads/' + data.uid + '/recommendationLetters.1');
    const [url10, setUrl10] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters9)
        .then((res) => {
            console.log(res);

            setUrl10(res);

        }).catch((error) => {
            console.log(error);
        });


    const listRefrecommendationLetters10 = ref(storage, 'uploads/' + data.uid + '/recommendationLetters.2');
    const [url11, setUrl11] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters10)
        .then((res) => {
            console.log(res);

            setUrl11(res);

        }).catch((error) => {
            console.log(error);
        });

    const listRefrecommendationLetters11 = ref(storage, 'uploads/' + data.uid + '/recommendationLetters.3');
    const [url12, setUrl12] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters11)
        .then((res) => {
            console.log(res);

            setUrl12(res);

        }).catch((error) => {
            console.log(error);
        });




    const listRefrecommendationLetters12 = ref(storage, 'uploads/' + data.uid + '/recommendationLetters.4');
    const [url13, setUrl13] = useState('');

    // let secondDownload;
    getDownloadURL(listRefrecommendationLetters12)
        .then((res) => {
            console.log(res);

            setUrl13(res);

        }).catch((error) => {
            console.log(error);
        });


    //     const listRefrecommendationLetters13 = ref(storage, 'uploads/' + data.uid + '/recommendationLetters.5');
    // const [url14, setUrl14] = useState('');

    // // let secondDownload;
    // getDownloadURL(listRefrecommendationLetters13)
    //     .then((res) => {
    //         console.log(res);

    //         setUrl14(res);

    //     }).catch((error) => {
    //         console.log(error);
    //     });



    const personalData = data.category === 'Ciência'
        ? { ...data.personalData, ...data.evidenceOfRecognition1, ...data.personalData, ...data.scientificProduction1, ...data.bibliometricInformation1, ...data.extraData1 }
        : { ...data.personalData, ...data.additionalInformation1, ...data.technologicalContributions3, ...data.technologicalContributions4, }


    console.log('here', personalData.otherRelevantInformation)


    return data.category === 'Ciência' ? (
        <>
            <FormLabel className="formLabel">
                Liste os 10 artigos de maior importância na sua área de pesquisa pulicado em revistas:
            </FormLabel>
            <TextInput
                fullWidth
                id="mostImportantArticlesInYourResearch"
                name="mostImportantArticlesInYourResearch"
                type="mostImportantArticlesInYourResearch"
                value={personalData.mostImportantArticlesInYourResearch}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />

            <FormLabel className="formLabel">
                Liste os seus 10 artigos mais citados e os respectivos números de citações:
            </FormLabel>
            <TextInput
                fullWidth
                id="mostCitedArticlesAndCitationNumbers"
                name="mostCitedArticlesAndCitationNumbers"
                value={personalData.mostCitedArticlesAndCitationNumbers}
                type="mostCitedArticlesAndCitationNumbers"
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />

            <FormLabel className="formLabel">
                Liste os capítulos de livros e artigos publicados a convite de editores de revistas:
            </FormLabel>
            <TextInput
                fullWidth
                id="publishedBookChaptersAndArticles"
                name="publishedBookChaptersAndArticles"
                value={personalData.publishedBookChaptersAndArticles}
                type="publishedBookChaptersAndArticles"
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />

            <FormLabel className="formLabel">
                Liste os livros publicados:
            </FormLabel>
            <TextInput
                fullWidth
                id="listPublishedBooks"
                name="listPublishedBooks"
                type="listPublishedBooks"
                value={personalData.listPublishedBooks}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />

            <FormLabel className="formLabel">
                Liste as patentes concedidas pelo INPI e instituições estrangeiras:
            </FormLabel>
            <TextInput
                fullWidth
                id="patentsGrantedByINPI"
                name="patentsGrantedByINPI"
                type="patentsGrantedByINPI"
                value={personalData.patentsGrantedByINPI}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />




            <FormLabel className="formLabel">
                Liste as academias de Ciência nas quais é membro:
            </FormLabel>
            <TextInput
                fullWidth
                id="scienceAcademiesWhichYouAreMember"
                name="scienceAcademiesWhichYouAreMember"
                type="scienceAcademiesWhichYouAreMember"
                value={personalData.scienceAcademiesWhichYouAreMember}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">
                Liste seus prêmios nacionais e internacionais mais relevantes, descrevendo brevemente a importância de cada um:
            </FormLabel>
            <TextInput
                fullWidth
                id="listOfMostRelevantAwardsWithDescription"
                name="listOfMostRelevantAwardsWithDescription"
                type="listOfMostRelevantAwardsWithDescription"
                value={personalData.listOfMostRelevantAwardsWithDescription}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">
                Liste suas plenary e keynote talks em eventos no Brasil e no exterior:
            </FormLabel>
            <TextInput
                fullWidth
                id="listPlenaryAndKeynoteTalks"
                name="listPlenaryAndKeynoteTalks"
                type="listPlenaryAndKeynoteTalks"
                value={personalData.listPlenaryAndKeynoteTalks}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">
                Liste as posições atuais e anteriores na editoria de revistas científicas:
            </FormLabel>
            <TextInput
                fullWidth
                id="listCurrentPositionsEditorshipInJournals"
                name="listCurrentPositionsEditorshipInJournals"
                type="listCurrentPositionsEditorshipInJournals"
                value={personalData.listCurrentPositionsEditorshipInJournals}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">
                Liste os congressos nacionais e internacionais organizados como chairman:
            </FormLabel>
            <TextInput
                fullWidth
                id="listCongressesOrganizesAsChairman"
                name="listCongressesOrganizesAsChairman"
                type="listCongressesOrganizesAsChairman"
                value={personalData.listCongressesOrganizesAsChairman}
                minRows={4}
                maxRows={7}
                multiline
                disabled
            />


            <FormLabel className="formLabel">
                Liste as posições de liderança atuais e passadas em sociedades e instituições científicas do Brasil e internacionais:
            </FormLabel>
            <TextInput
                fullWidth
                id="listLeadershipPositionsScientificSocieties"
                name="listLeadershipPositionsScientificSocieties"
                type="listLeadershipPositionsScientificSocieties"
                value={personalData.listLeadershipPositionsScientificSocieties}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />



            <FormLabel className="formLabel">
                Número de artigos científicos publicados em periódicos com peer review indexados pelo Scopus:
            </FormLabel>
            <TextInput
                fullWidth
                id="scientificArticlesPublishedIndexedByScopus"
                name="scientificArticlesPublishedIndexedByScopus"
                type="scientificArticlesPublishedIndexedByScopus"
                value={personalData.scientificArticlesPublishedIndexedByScopus}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">Tem quantas citações (Scopus)?</FormLabel>
            <TextInput
                fullWidth
                id="totalCitations"
                name="totalCitations"
                type="totalCitations"
                value={personalData.totalCitations}
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">Quantos alunos de iniciação científica, mestrado, doutorado e pós-doutorado já orientou?</FormLabel>
            <TextInput
                fullWidth
                id="totalScientificInitiationStudentsSupervised"
                name="totalScientificInitiationStudentsSupervised"
                type="totalScientificInitiationStudentsSupervised"
                value={personalData.totalScientificInitiationStudentsSupervised}
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">
                Qual é o seu H-index (Scopus)? Compare com seus pares do Brasil e exterior.
            </FormLabel>
            <TextInput
                fullWidth
                id="hIndexScopusScore"
                name="hIndexScopusScore"
                value={personalData.hIndexScopusScore}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">
                Qual é o seu Leadership Index (LI)? LI é a razão entre o número de artigos nos quais o seu H-index é maior que dos demais coautores e o número total de (seus) artigos em periódicos indexados pelo Scopus (LI {"<="}1).
            </FormLabel>
            <TextInput
                fullWidth
                id="leadershipIndex"
                name="leadershipIndex"
                value={personalData.leadershipIndex}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />



            <FormLabel className="formLabel">Descreva outras informações relevantes:</FormLabel>
            <TextInput
                fullWidth
                id="otherRelevantInformation"
                name="otherRelevantInformation"
                type="otherRelevantInformation"
                value={personalData?.otherRelevantInformation}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />

            <FormLabel className="formLabel">Descreva em uma uma página, suas descobertas científicas mais relevantes:</FormLabel>
            <TextInput
                fullWidth
                id="shortRelevantScientificDiscoveries"
                name="shortRelevantScientificDiscoveries"
                type="otherRelevantInformation"
                value={personalData.shortRelevantScientificDiscoveries}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />




            <div className="section-one">


                {data.uid === 'PZk2LT6HzycHRyWrfkFbOBrpCpa2' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url2} >
                                Download
                            </a>
                        </div>
                    </div>
                }



                {data.uid === 'PZk2LT6HzycHRyWrfkFbOBrpCpa2' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url3} >
                                Download
                            </a>
                        </div>
                    </div>
                }

                {data.uid === 'PZk2LT6HzycHRyWrfkFbOBrpCpa2' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url4} >
                                Download
                            </a>
                        </div>
                    </div>
                }

                {data.uid === 'PZk2LT6HzycHRyWrfkFbOBrpCpa2' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url5} >
                                Download
                            </a>
                        </div>
                    </div>
                }


                {data.uid === 'PZk2LT6HzycHRyWrfkFbOBrpCpa2' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url6} >
                                Download
                            </a>
                        </div>
                    </div>
                }

                {data.uid === 'PZk2LT6HzycHRyWrfkFbOBrpCpa2' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url7} >
                                Download
                            </a>
                        </div>
                    </div>
                }

                {data.uid === 'PZk2LT6HzycHRyWrfkFbOBrpCpa2' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url8} >
                                Download
                            </a>
                        </div>
                    </div>
                }

                {data.uid === 'PZk2LT6HzycHRyWrfkFbOBrpCpa2' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url9} >
                                Download
                            </a>
                        </div>
                    </div>
                }

                {data.uid === 'PZk2LT6HzycHRyWrfkFbOBrpCpa2' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url20} >
                                Download
                            </a>
                        </div>
                    </div>
                }

                {/* {data.uid === 'wWkQkTgxduVaPGrPS7ESSQ8kRl12' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url21} >
                                Download
                            </a>
                        </div>
                    </div>
                } */}

                {/* {data.uid === 'wWkQkTgxduVaPGrPS7ESSQ8kRl12' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url22} >
                                Download
                            </a>
                        </div>
                    </div>
                } */}


                {data.uid === 'Lzk1YqNLhdWjTBlofnanqwJHGbX2' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url23} >
                                Download
                            </a>
                        </div>
                    </div>
                }


                {data.uid === 'Lzk1YqNLhdWjTBlofnanqwJHGbX2' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url24} >
                                Download
                            </a>
                        </div>
                    </div>
                }


                {data.uid === 'wWkQkTgxduVaPGrPS7ESSQ8kRl12' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url25} >
                                Download
                            </a>
                        </div>
                    </div>
                }


                {data.uid === 'wWkQkTgxduVaPGrPS7ESSQ8kRl12' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url26} >
                                Download
                            </a>
                        </div>
                    </div>
                }


                {data.uid === 'wWkQkTgxduVaPGrPS7ESSQ8kRl12' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url27} >
                                Download
                            </a>
                        </div>
                    </div>
                }

                {data.uid === 'Lzk1YqNLhdWjTBlofnanqwJHGbX2' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url28} >
                                Download
                            </a>
                        </div>
                    </div>
                }

                {data.uid === 'cdn1GFtBAJRCopyDlzSGM1wbsPt1' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url29} >
                                Download
                            </a>
                        </div>
                    </div>
                }


                <div className="div-Recommendation">
                    <div>
                        <TextInput
                            label="Cartas de recomendação:*"
                            id="Cartas de recomendação:*"
                            name="recommendationLetters"
                            value={personalData.recommendationLetters}
                            disabled
                        />
                    </div>
                    <div>
                        <a className="btn-Download" target="_blank" href={url1} >
                            Download
                        </a>
                    </div>
                </div>
                <div className="div-Lattes">
                    <div>
                        <TextInput
                            label="Currículo Vitae Lattes:*"
                            id="Currículo Vitae Lattes:*"
                            name="curriculum"
                            value={personalData?.curriculum}
                            disabled
                        />
                    </div>
                    <div>
                        <a className="btn-Download" target="_blank" href={url} >
                            Download
                        </a>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <>
            <FormLabel className="formLabel">O grau de inovação introduzido:</FormLabel>
            <TextInput
                fullWidth
                id="degreeOfInnovation"
                name="degreeOfInnovation"
                type="degreeOfInnovation"
                value={personalData.degreeOfInnovation}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />

            <FormLabel className="formLabel">A relevância de tais contribuições, destacando os impactos econômicos, sociais ou ambientais. Nota: O item requer a comprovação inequívoca das contribuições.</FormLabel>
            <TextInput
                fullWidth
                id="relevanceOfContributions"
                name="relevanceOfContributions"
                type="relevanceOfContributions"
                value={personalData.relevanceOfContributions}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />

            <FormLabel className="formLabel">Apresente evidências do reconhecimento (por terceiros) da significância das contribuições tecnológicas:</FormLabel>
            <TextInput
                fullWidth
                id="evidenceOfRecognition"
                name="evidenceOfRecognition"
                type="evidenceOfRecognition"
                value={personalData.evidenceOfRecognition}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />

            <FormLabel className="formLabel">Aponte a forma de reconhecimento da qualificação e contribuições do candidato pela manifestação por lideranças empresariais, administradores de órgãos públicos, outras instituições privadas e governamentais, comprovação de reconhecimento internacional e outras manifestações pertinentes:*</FormLabel>
            <TextInput
                fullWidth
                id="formOfRecognition"
                name="formOfRecognition"
                type="formOfRecognition"
                value={personalData.formOfRecognition}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">Quantas empresas de tecnologia foram criadas ou ajudou a criar?</FormLabel>
            <TextInput
                fullWidth
                id="technologyCompaniesCreated"
                name="technologyCompaniesCreated"
                type="technologyCompaniesCreated"
                value={personalData.technologyCompaniesCreated}
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">Lista das empresas de tecnologia criadas ou que ajudou a criar. Nota: O item requer a comprovação inequívoca das empresas criadas.</FormLabel>
            <TextInput
                fullWidth
                id="listOfTechnologyCompaniesCreated"
                name="listOfTechnologyCompaniesCreated"
                type="listOfTechnologyCompaniesCreated"
                value={personalData.listOfTechnologyCompaniesCreated}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Apresente demonstração de empresas que se beneficiaram de suas contribuições tecnológicas:</FormLabel>
            <TextInput
                fullWidth
                id="demonstrationOfCompaniesBenefited"
                name="demonstrationOfCompaniesBenefited"
                type="demonstrationOfCompaniesBenefited"
                value={personalData.demonstrationOfCompaniesBenefited}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Liste as patentes concedidas pelo INPI ou agências estrangeiras:</FormLabel>
            <TextInput
                fullWidth
                id="patentsGrantedByINPI"
                name="patentsGrantedByINPI"
                type="patentsGrantedByINPI"
                value={personalData.patentsGrantedByINPI}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Quantas patentes foram licenciadas?</FormLabel>
            <TextInput
                fullWidth
                id="patentsLicensed"
                name="patentsLicensed"
                type="patentsLicensed"
                value={personalData.patentsLicensed}
                minRows={4}
                maxRows={7}
                disabled
            />


            <FormLabel className="formLabel">Descreva o licenciamento de patentes a empresas no país e no exterior, destacando os impactos econômicos. Nota: O item requer a comprovação inequívoca dos impactos econômicos.</FormLabel>
            <TextInput
                fullWidth
                id="licensingOfPatentsToCompanies"
                name="licensingOfPatentsToCompanies"
                type="licensingOfPatentsToCompanies"
                value={personalData.licensingOfPatentsToCompanies}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Descreva o desenvolvimento de produtos e processos que não foram patenteados:</FormLabel>
            <TextInput
                fullWidth
                id="developmentOfProductsAndProcessesBeenPatented"
                name="developmentOfProductsAndProcessesBeenPatented"
                type="developmentOfProductsAndProcessesBeenPatented"
                value={personalData.developmentOfProductsAndProcessesBeenPatented}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Indique os produtos desenvolvidos que estão no mercado:</FormLabel>
            <TextInput
                fullWidth
                id="developedProductsOnMarket"
                name="developedProductsOnMarket"
                type="developedProductsOnMarket"
                value={personalData.developedProductsOnMarket}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Indique processos tecnológicos desenvolvidos que estão em execução por empresas:</FormLabel>
            <TextInput
                fullWidth
                id="developedTechnologicalProcessesExecutedByCompanies"
                name="developedTechnologicalProcessesExecutedByCompanies"
                type="developedTechnologicalProcessesExecutedByCompanies"
                value={personalData.developedTechnologicalProcessesExecutedByCompanies}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Inclua cargos ocupados relativos às atuações em sistemas tecnológicos:</FormLabel>
            <TextInput
                fullWidth
                id="activitiesInTechnologicalSystems"
                name="activitiesInTechnologicalSystems"
                type="activitiesInTechnologicalSystems"
                value={personalData.activitiesInTechnologicalSystems}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Inclua consultoria a empresas e organizações tecnológicas:</FormLabel>
            <TextInput
                fullWidth
                id="consultancyToTechnologyCompanies"
                name="consultancyToTechnologyCompanies"
                type="consultancyToTechnologyCompanies"
                value={personalData.consultancyToTechnologyCompanies}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Descreva os principais prêmios descrevendo a importância de cada um:</FormLabel>
            <TextInput
                fullWidth
                id="mainAwardsWithImportance"
                name="mainAwardsWithImportance"
                type="mainAwardsWithImportance"
                value={personalData.mainAwardsWithImportance}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Descreva sua atuação na formação de recursos humanos que produziram impactos em inovação:</FormLabel>
            <TextInput
                fullWidth
                id="trainingHumanResourcesImpactedInnovation"
                name="trainingHumanResourcesImpactedInnovation"
                type="trainingHumanResourcesImpactedInnovation"
                value={personalData.trainingHumanResourcesImpactedInnovation}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Descreva a sua familiaridade no uso e aplicação de tecnologias modernas (exemplos: transformação digital, inteligência artificial, nanotecnologia, internet das coisas, entre outros).</FormLabel>
            <TextInput
                fullWidth
                id="familiarityWithModernTechnologies"
                name="familiarityWithModernTechnologies"
                value={personalData.familiarityWithModernTechnologies}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Descreva suas publicações técnico-científicas mais relevantes (textos, relatórios industriais, etc.):</FormLabel>
            <TextInput
                fullWidth
                id="mostRelevantTechnicalScientificPublication"
                name="mostRelevantTechnicalScientificPublication"
                type="mostRelevantTechnicalScientificPublication"
                value={personalData.mostRelevantTechnicalScientificPublication}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />


            <FormLabel className="formLabel">Outras informações relevantes:</FormLabel>
            <TextInput
                fullWidth
                id="otherRelevantInformation"
                name="otherRelevantInformation"
                type="otherRelevantInformation"
                value={personalData.otherRelevantInformation}
                disabled
                multiline
                minRows={4}
                maxRows={7}
            />

            <FormLabel className="formLabel">Descreva em uma uma página, suas descobertas científicas mais relevantes:</FormLabel>
            <TextInput
                fullWidth
                id="shortCurriculum"
                name="shortCurriculum"
                type="shortCurriculum"
                value={personalData.shortCurriculum}
                multiline
                minRows={4}
                maxRows={7}
                disabled
            />

            <div className="section-one">


                {data.uid === 'yNgOccxvUiXQRsBY9ytcBUPN01P2' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={urlOnly} >
                                Download
                            </a>
                        </div>
                    </div>
                }

                {data.uid === '7VWGQDO0C9SeTBr8b09Sf3N4Rnu2' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url10} >
                                Download
                            </a>
                        </div>
                    </div>
                }

                {data.uid === '7VWGQDO0C9SeTBr8b09Sf3N4Rnu2' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url11} >
                                Download
                            </a>
                        </div>
                    </div>
                }

                {data.uid === '7VWGQDO0C9SeTBr8b09Sf3N4Rnu2' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url12} >
                                Download
                            </a>
                        </div>
                    </div>
                }

                {data.uid === '7VWGQDO0C9SeTBr8b09Sf3N4Rnu2' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={url13} >
                                Download
                            </a>
                        </div>
                    </div>
                }




                {data.uid === 'yNgOccxvUiXQRsBY9ytcBUPN01P2' &&

                    <div className="div-Recommendation">
                        <div>
                            <TextInput
                                label="Cartas de recomendação:*"
                                id="Cartas de recomendação:*"
                                name="recommendationLetters"
                                value={personalData.recommendationLetters}
                                disabled
                            />
                        </div>
                        <div>
                            <a className="btn-Download" target="_blank" href={urlOnly1} >
                                Download
                            </a>
                        </div>
                    </div>
                }



                <div className="div-Recommendation">
                    <div>
                        <TextInput
                            label="Cartas de recomendação:*"
                            id="Cartas de recomendação:*"
                            name="recommendationLetters"
                            value={personalData.recommendationLetters}
                            disabled
                        />
                    </div>
                    <div>
                        <a className="btn-Download" target="_blank" href={url1} >
                            Download
                        </a>
                    </div>
                </div>
                <div className="div-Lattes">
                    <div>
                        <TextInput
                            label="Currículo Vitae Lattes:*"
                            id="Currículo Vitae Lattes:*"
                            name="curriculum"
                            value={personalData?.curriculum}
                            disabled
                        />
                    </div>
                    <div>
                        <a className="btn-Download" target="_blank" href={url} >
                            Download
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

