
import { FormLabel } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { Button } from '../../../components/Button'
import { TextInput } from '../../../components/TextInput'
import { useAuth } from '../../../hooks/useAuth'
import { routesNames } from '../../../routes/routesNames'
import { baseColors } from '../../../styles'
import { ConfirmPopupBack } from '../../../components/ConfirmPopupBack'
import { ConfirmPopupSaveback } from '../../../components/ConfirmPopupSaveback'


import './styles.scss'
import { TextInputChildren } from '../../../components/TextInputChildren'
import { ButtonStages } from '../../../components/ButtonStages'
import { ButtonStagesBack } from '../../../components/ButtonStagesBack'



const validationSchema = yup.object({
	mostImportantArticlesInYourResearch: yup
		.string(),
	mostCitedArticlesAndCitationNumbers: yup
		.string(),
	publishedBookChaptersAndArticles: yup
		.string(),
	listPublishedBooks: yup
		.string(),
	patentsGrantedByINPI: yup
		.string(),
})


export function ScientificProduction() {
	const navigate = useNavigate();
	const userContext = useAuth()


	const formik = useFormik({
		initialValues: {
			mostImportantArticlesInYourResearch: '',
			mostCitedArticlesAndCitationNumbers: '',
			publishedBookChaptersAndArticles: '',
			listPublishedBooks: '',
			patentsGrantedByINPI: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			userContext.updateUser({
				scientificProduction1: values
			})

			navigate(routesNames.evidenceOfRecognition.path)
		},
	})


	useEffect(() => {
		if (userContext?.user?.scientificProduction1) {
			Object.keys(userContext?.user?.scientificProduction1 || {}).forEach(key => {
				formik.setFieldValue(key, userContext?.user?.scientificProduction1[key])
			})
		} else if(userContext?.user?.scientificProduction){
			Object.keys(userContext?.user?.scientificProduction || {}).forEach(key => {
				formik.setFieldValue(key, userContext?.user?.scientificProduction[key])
			})

		}
	}, [userContext?.user?.scientificProduction])



	return (
		<div className='formContainerCiencia'>
			<h2 className='textDadospessoais'>Conte um pouco sobre os seus feitos para concluir a inscrição.</h2>

			<form onSubmit={formik.handleSubmit}>



				<FormLabel className="formLabel">
					<span className='textScienticProd'>Liste os 10 artigos de maior importância na<br /> sua área de pesquisa pulicado em revistas:</span>
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="mostImportantArticlesInYourResearch"
						name="mostImportantArticlesInYourResearch"
						value={formik.values.mostImportantArticlesInYourResearch}
						onChange={formik.handleChange}
						error={formik.touched.mostImportantArticlesInYourResearch && Boolean(formik.errors.mostImportantArticlesInYourResearch)}
						helperText={formik.touched.mostImportantArticlesInYourResearch && formik.errors.mostImportantArticlesInYourResearch}
						multiline
						minRows={4}
						maxRows={7}
					/>
				</div>

				<FormLabel className="formLabel">
					<span className='textScienticProd'>Liste os seus dez (10) artigos mais citados e os respectivos números de citações:</span>
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="mostCitedArticlesAndCitationNumbers"
						name="mostCitedArticlesAndCitationNumbers"
						value={formik.values.mostCitedArticlesAndCitationNumbers}
						onChange={formik.handleChange}
						error={formik.touched.mostCitedArticlesAndCitationNumbers && Boolean(formik.errors.mostCitedArticlesAndCitationNumbers)}
						helperText={formik.touched.mostCitedArticlesAndCitationNumbers && formik.errors.mostCitedArticlesAndCitationNumbers}
						multiline
						minRows={4}
						maxRows={7}
					/>
				</div>

				<FormLabel className="formLabel">
					<span className='textScienticProd'>Liste os capítulos de livros e artigos publicados a convite de editores de revistas:</span>
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="publishedBookChaptersAndArticles"
						name="publishedBookChaptersAndArticles"
						value={formik.values.publishedBookChaptersAndArticles}
						onChange={formik.handleChange}
						error={formik.touched.publishedBookChaptersAndArticles && Boolean(formik.errors.publishedBookChaptersAndArticles)}
						helperText={formik.touched.publishedBookChaptersAndArticles && formik.errors.publishedBookChaptersAndArticles}
						multiline
						minRows={4}
						maxRows={7}
					/>
				</div>

				<FormLabel className="formLabel">
					<span className='textScienticProd'>Liste os livros publicados:</span>
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="listPublishedBooks"
						name="listPublishedBooks"
						value={formik.values.listPublishedBooks}
						onChange={formik.handleChange}
						error={formik.touched.listPublishedBooks && Boolean(formik.errors.listPublishedBooks)}
						helperText={formik.touched.listPublishedBooks && formik.errors.listPublishedBooks}
						multiline
						minRows={4}
						maxRows={7}
					/>
				</div>
				<FormLabel className="formLabel">
					<span className='textScienticProd'>Liste as patentes concedidas pelo INPI e instituições estrangeiras:</span>
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="patentsGrantedByINPI"
						name="patentsGrantedByINPI"
						value={formik.values.patentsGrantedByINPI}
						onChange={formik.handleChange}
						error={formik.touched.patentsGrantedByINPI && Boolean(formik.errors.patentsGrantedByINPI)}
						helperText={formik.touched.patentsGrantedByINPI && formik.errors.patentsGrantedByINPI}
						multiline
						minRows={4}
						maxRows={7}
					/>
				</div>

				<div className='row-gap last'>
					<ButtonStages
						type="outline"
						label="SALVAR E SAIR"
						color={baseColors.lightIndigo}
						onClick={() => {
							setTimeout(() => {
								userContext?.signOutUser()
							}, 200);
						}}
					/>

					<ButtonStagesBack
						type="outline"
						label="VOLTAR"
						color={baseColors.lightIndigo}
						typeSubmit
						onClick={() => navigate(-1)}
					/>


					<Button
						style={{
							cursor: 'pointer',
							padding: '10px 50px',
							fontWeight: 400,
							fontSize: 16,
							fontFamily: "Open Sans",
							textAlign: 'center',
							marginRight: 40,

						}}

						label="AVANÇAR"
						color={baseColors.lightIndigo} />

					{/* <Button
						label="Salvar e avançar"
						color={baseColors.darkBlue}
					// style={{marginTop: formik.touched.acceptTerms && Boolean(formik.errors.acceptTerms) ? 0 : 40}}
					/> */}
				</div>

				<ConfirmPopupBack />
				<ConfirmPopupSaveback />

			</form>

		</div>
	)
}
