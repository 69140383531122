
export const SidebarData = [
    {
        title: 'Inscrições 2023',
        path: '/admin/inscricoes',
        //   icon: <AiIcons.AiFillHome />,
    },
    {
        title: 'Instituições 2023',
        path: '/admin/instituicoes',
        //   icon: <AiIcons.AiFillHome />,
    },
    {
        title: 'Inscrições 2024',
        path: '/admin/inscricoes2024',
        //   icon: <AiIcons.AiFillHome />,
    },
    {
        title: 'Inscrições 10/05',
        path: '/admin/inscricoes1005',
        //   icon: <AiIcons.AiFillHome />,
    },
    {
        title: 'Instituições 2024',
        path: '/admin/instituicoes2024',
        //   icon: <AiIcons.AiFillHome />,
    },
    
]
