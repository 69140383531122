
import { useNavigate, } from 'react-router-dom'

import './styles.scss'
import { Button } from '../../components/Button'
import { baseColors } from '../../styles'
import { routesNames } from '../../routes/routesNames'
// import { firestoreDatabase } from '../../services/firebase'
// import { addDoc, collection } from 'firebase/firestore'

export function ThankYou() {

    const navigate = useNavigate();

    // async function handleClick() {
    //     await addDoc(collection(firestoreDatabase, 'mail'), {
    //         to: ['jonas.henrique@lapisraro.com.br', 'gilberto.junior@lapisraro.com.br', 'carolz@lapisraro.com.br'],
    //         message: {
    //             subject: 'Boas vindas da CBMM!',
    //             text: 'Boas vindas em plaintext.',
    //             html: 'Boas vindas em <code>HTML</code>.',
    //         }
    //     })
    // }

    return (
        <div className='thankYou formContainerThankYou'>
            <h2 className='titleThankYou'>
                Obrigado!
            </h2>

            <br />

            <h3 className='textThankYou'>
                A sua inscrição foi concluída com sucesso!
            </h3>

            <h4 className='subTextThankYou'>
                Você receberá uma confirmação
                <br />
                no e-mail cadastrado.
            </h4>

            <br />
            <br />
            <br />

            <Button
                style={{
                    cursor: 'pointer',
                    padding: '10px 150px 10px 150px',
                    fontWeight: 800,
                    fontSize: 16,
                    fontFamily: "Open Sans",
                    textAlign: 'center',
                }}

                label="FINALIZAR"
                color={baseColors.lightIndigo} 
                onClick={() => navigate(routesNames.login.path)}/>
        </div>
    )
}

