import { useEffect, useState } from "react";
import { SideBarNavigator } from "../../components/SideBar";
import { Admin } from "../../core/Controllers/Admin";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ViewRegisterData2024 } from "../../components/ViewRegisterData2024";
import { firestoreDatabase } from "../../services/firebase";

export function AdminInsc2024() {
    const [data, setData] = useState<any>([]);
    const [open, setOpen] = useState(false);
    const [rowData, setRowData] = useState<any>([]);

    useEffect(() => {
        Admin().getUsersData().then((users: any) => setData(users));
    }, []);

    // Adicione os imports necessários do Firebase (serverTimestamp, addDoc, collection) aqui

    useEffect(() => {
        data.forEach((item: any) => {
            // Adapte para o seu código real de adição ao Firestore aqui
            // addDoc(collection(firestoreDatabase, 'oldData'), {
            //     ...item, createdAt: serverTimestamp()
            // });
        });
    }, []);

    useEffect(() => {
        // User().getAllUsersFinalReport()
        // User().getAllInstitutionsDataForReport().then((data) => { console.table(data) })
        //User().getAllIndicationsDataForReport().then((data) => { console.table(data) })
    }, []);

    async function handleEnframe(data: any) {
        const newData = { ...data, enframe2024: !data.enframe2024, noenframe2024: false };
        Admin().enframe(newData);
        setData((old: any) => old.map((dataToUpdate: any) => dataToUpdate.uid === data.uid ? newData : dataToUpdate));
        setOpen(false);
    }

    async function handleEnframe1(data: any) {
        const newData = { ...data, desc2024: !data.desc2024 };
        Admin().enframe1(newData);
        setData((old: any) => old.map((dataToUpdate: any) => dataToUpdate.uid === data.uid ? newData : dataToUpdate));
        setOpen(false);
    }

    async function handleEnframe2(data: any) {
        const newData = { ...data, noenframe2024: !data.noenframe2024, enframe2024: false };
        Admin().enframe2(newData);
        setData((old: any) => old.map((dataToUpdate: any) => dataToUpdate.uid === data.uid ? newData : dataToUpdate));
        setOpen(false);
    }

    return (
        <>
            <SideBarNavigator />
            <div style={{ marginLeft: 220 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650, width: 1300 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Categoria</TableCell>
                                <TableCell>Telefone</TableCell>
                                <TableCell align="right">Cadastro completo</TableCell>
                                <TableCell align="right">Enquadramento</TableCell>
                                <TableCell align="right">Desclassificado</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row: any) => {

                                let updatedAtDate: any;



                                if (row.updatedAt.nanoseconds) {

                                    updatedAtDate = new Date(row.updatedAt.seconds * 1000 + row.updatedAt.nanoseconds / 1000000)

                                } else {
                                    updatedAtDate = new Date(row.updatedAt);
                                }

                                


                                if (updatedAtDate.getFullYear() === 2024 ) {


                                    console.log('data', updatedAtDate.getDate() )
                                console.log('mes', updatedAtDate.getMonth() )

                                    return (
                                        <TableRow
                                            key={row.uid}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setRowData(row)
                                                setOpen(true)
                                            }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{row.category}</TableCell>
                                            <TableCell>{row.phoneNumber}</TableCell>
                                            <TableCell align="right">
                                                <Chip label={row.completed ? 'Sim' : row.completed2024 ? 'Sim' : 'Não'} color={row.completed ? 'primary' : row.completed2024 ? 'primary' : 'warning'} variant={row.completed ? 'filled' : row.completed2024 ? 'filled' : 'outlined'} />
                                            </TableCell>
                                            <TableCell align="right">
                                                {/* {row.enframe === undefined ? '-' : 
                                            <Chip label={row.enframe ? 'Sim' : 'Não'} color={row.enframe ? 'success' : 'error'} variant='filled' />
                                        } */}
                                                {row.enframe2024
                                                    ? <Chip label={'Sim'} color={'success'} variant='filled' />
                                                    : row.noenframe2024 === true ? <Chip label={'Não'} color={'error'} variant='filled' /> : '-'
                                                }
                                              

                                             
                                            </TableCell>
                                            <TableCell align="right">
                                                {/* {row.enframe === undefined ? '-' : 
                                            <Chip label={row.enframe ? 'Sim' : 'Não'} color={row.enframe ? 'success' : 'error'} variant='filled' />
                                        } */}
                                                {row.desc2024
                                                    ? <Chip label={row.desc2024 ? 'Sim' : 'Não'} color={row.desc2024 ? 'success' : 'error'} variant='filled' />
                                                    : '-'
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Dialog
                    maxWidth={'lg'}
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <DialogTitle>Dados da inscrição</DialogTitle>
                    <DialogContent>
                        <Box
                            noValidate
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                                width: 'fit-content',
                            }}
                        >
                            <ViewRegisterData2024 data={{ ...rowData }} />
                        </Box>
                    </DialogContent>
                    <DialogActions style={{ gap: 20, marginRight: 30 }}>
                        <Button onClick={() => handleEnframe(rowData)} color={"success"} variant="contained">{'Enquadrar'}</Button>
                        <Button onClick={() => handleEnframe2(rowData)} color={"error"} variant="contained">{'Não enquadrar' }</Button>
                        <Button onClick={() => handleEnframe1(rowData)} color={!rowData.desc2024 ? "success" : "error"} variant="contained">{!rowData.desc2024 ? 'Desclassificar' : 'Retirar desclassificação'}</Button>
                        <Button onClick={() => setOpen(false)} variant="contained">Fechar</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}
