import { getAuth, onAuthStateChanged } from "firebase/auth"
import { createContext, useState, useEffect } from "react"
import { Institution } from "../core/Controllers/Institution"
import { User } from "../core/Controllers/User"

export const AuthContext = createContext<any | null>(null)

const AUTH_STORAGE_TOKEN = 'CBMM' + "_AUTH"

export function AuthContextProvider({ children } : {children: any}) {

    const [user, setUser] = useState(JSON.parse(sessionStorage.getItem(AUTH_STORAGE_TOKEN) || '{}'))


    function updateUser(newInfos : any) {
        setUser((oldUser : any) => {
            sessionStorage.setItem(AUTH_STORAGE_TOKEN, JSON.stringify({ ...oldUser, ...newInfos }))
            return { ...oldUser, ...newInfos }
        })
    }

    async function signOutUser() {
        User().logout().then(() => {
            setUser(null)
            sessionStorage.clear()
            localStorage.clear()
        })
    }

    useEffect(() => {
        console.log('user change: ', user)
        if (!user) return
        if (!user.uid) return
        
        console.log('user change: ', user)

        if (user?.type === 'institution') {
            Institution().update({uid: user.uid, ...user})
        } else {
            User().update({uid: user.uid, ...user})
        }

    }, [user])


    useEffect(() => {
        const auth = getAuth();

        const unsubscribe = onAuthStateChanged(auth, user => {
            if (user) {
                const { uid } = user
                if (!uid) {
                    signOutUser()
                }
            } else {
                signOutUser()
            }
        })

        return () => {
            unsubscribe()
        }
    }, [])

    return (
        <AuthContext.Provider value={{ user, updateUser, signOutUser }}>
            {children}
        </AuthContext.Provider>
    )
}
