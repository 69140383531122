
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { User } from '../../core/Controllers/User'
import { useAuth } from '../../hooks/useAuth'
import { routesNames } from '../../routes/routesNames'
import { baseColors } from '../../styles'

import './styles.scss'


export function ConfirmEmail() {

    const navigate = useNavigate()
    const userData = useAuth()
    
    async function handleClick() {
        window.location.reload()
    }

    useEffect(() => {
        const auth = getAuth();

        const unsubscribe = onAuthStateChanged(auth, user => {
            if (user) {
                const { uid, emailVerified } = user
                if (uid && emailVerified) {
                    userData.updateUser({
                        emailVerified: true
                    })

                    if (userData?.user?.type === 'institution') {
                        navigate(routesNames.InstitutionIndication.path)
                        return
                    }
                    navigate(routesNames.technologyPersonalData.path)
                    return
                }
            }
        })

        return () => {
            unsubscribe()
        }
    }, [navigate, userData])

    useEffect(() => {
        User().sendConfirmEmail()
    }, [])

    return (
        <div className='confirmEmail formContainer'>
            <h4 className='titleConfirmEmail'>
                Confirme seu e-mail!
            </h4>

            <p>
                Você recebeu uma confirmação no email
            <br/>
                <strong>{userData?.user?.email}</strong>.
            <br/>
                Confirme para continuar
            </p>

            <Button
                style={{
                    cursor: 'pointer',
                    fontWeight: 800,
                    fontSize: 16,
                    fontFamily: "Open Sans",
                    textAlign: 'center',
                    width: '304px',
                    height: '45px',
                }}
                label='Já confirmei meu email'
                color={baseColors.lightIndigo} 
                onClick={handleClick}
            />
        </div>
    )
}

