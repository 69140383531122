import { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Header } from "../../components/Header";
import { routesNames } from "../../routes/routesNames";


export function PageLayout() {

    const {pathname} = useLocation();

    const actualRouteData = useMemo(() => {
        const routeArray = routesNames

        const _actualRouteData = Object.values(routeArray).find(route => route?.path === pathname)

        document.title = _actualRouteData?.pageTitle || routeArray.home.pageTitle
        return _actualRouteData
    }, [pathname])


    return (


        <><main id="main" style={{ position: "relative" }}>



            <section id={actualRouteData?.pageId || ''}>


                <Outlet />
            </section>

        </main></>
    )
}

