
import { FormLabel } from '@mui/material'
import { FieldArray, Formik, Form } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../components/Button'
import { ButtonStages } from '../../../components/ButtonStages'
import { ButtonStagesBack } from '../../../components/ButtonStagesBack'
import { InputFile } from '../../../components/InputFile'
import { TextInput } from '../../../components/TextInput'
import { useAuth } from '../../../hooks/useAuth'
import { routesNames } from '../../../routes/routesNames'
import { baseColors } from '../../../styles'


import './styles.scss'
import { ConfirmPopupSaveback } from '../../../components/ConfirmPopupSaveback'
import { ConfirmPopupBack } from '../../../components/ConfirmPopupBack'


export function AdditionalInformation() {
	const navigate = useNavigate();
	const userContext = useAuth()

	const [valid2, setValid2] = useState(false);
	const [valid3, setValid3] = useState(false);

	const [initialValues, setInitialValues] = useState({
		activitiesInTechnologicalSystems: '',
		consultancyToTechnologyCompanies: '',
		mainAwardsWithImportance: '',
		trainingHumanResourcesImpactedInnovation: '',
		familiarityWithModernTechnologies: '',
		mostRelevantTechnicalScientificPublication: '',
		otherRelevantInformation: '',
		recommendationLetters: [''],
		curriculum: ''
	})
	const [loading, setLoading] = useState(true)

	function onSubmit(values: any) {
		userContext.updateUser({
			additionalInformation1: values
		})


		let result = false;
		let count = 0;
		let count1 = 0;


		console.log(values.recommendationLetters);

		for (let x in values.recommendationLetters) {

			if (values.recommendationLetters[x].length > 0) {
				count++;

			}
			count1++;
		}

		if (count1 === count) {
			result = true;
		}



		if (values.curriculum.length > 0 && result) {

			navigate(routesNames.technologyRevision.path)

		} else {

			setValid2(true);
			setValid3(true);
		}
	}


	useEffect(() => {

		console.log('here', userContext.user.uid);

		if (userContext?.user?.additionalInformation1) {
			Object.keys(userContext?.user?.additionalInformation1 || {}).forEach(key => {
				setInitialValues(old => ({ ...old, [key]: userContext?.user?.additionalInformation1[key] }))
			})
		} else if (userContext?.user?.additionalInformation) {
			Object.keys(userContext?.user?.additionalInformation || {}).forEach(key => {
				setInitialValues(old => ({ ...old, [key]: userContext?.user?.additionalInformation[key] }))
			})
		}

		setLoading(false)
	}, [userContext?.user?.additionalInformation])


	return (

		<div className='formContainer'>
			<h2 className='textDadospessoais'>Outras informações</h2>

			{!loading && <Formik initialValues={initialValues} onSubmit={onSubmit}>
				{({ errors, values, touched, setValues }: { errors: any, values: any, touched: any, setValues: any }) => (

					<Form>

						{/* <Button
							label="Salvar e sair"
							type="outline"
							style={{ position: 'absolute', top: 0, right: 0 }}
							onClick={() => {
								setTimeout(() => {
									userContext?.signOutUser()
								}, 200);
							}}
						/> */}

						<FormLabel className="textScienticProd formLabel">Inclua cargos ocupados relativos às atuações em sistemas tecnológicos:</FormLabel>
						<div className='div-discoveries'>
							<TextInput
								fullWidth
								id="activitiesInTechnologicalSystems"
								name="activitiesInTechnologicalSystems"
								value={values.activitiesInTechnologicalSystems}
								onChange={(e: any) => setValues({ ...values, activitiesInTechnologicalSystems: e.target.value })}
								error={touched.activitiesInTechnologicalSystems && Boolean(errors.activitiesInTechnologicalSystems)}
								helperText={touched.activitiesInTechnologicalSystems && errors.activitiesInTechnologicalSystems}
								multiline
								minRows={4}
								maxRows={7}
							/>

						</div>
						<FormLabel className="textScienticProd formLabel">Inclua consultoria a empresas e organizações tecnológicas:</FormLabel>
						<div className='div-discoveries'>
							<TextInput
								fullWidth
								id="consultancyToTechnologyCompanies"
								name="consultancyToTechnologyCompanies"
								type="consultancyToTechnologyCompanies"
								value={values.consultancyToTechnologyCompanies}
								onChange={(e: any) => setValues({ ...values, consultancyToTechnologyCompanies: e.target.value })}
								error={touched.consultancyToTechnologyCompanies && Boolean(errors.consultancyToTechnologyCompanies)}
								helperText={touched.consultancyToTechnologyCompanies && errors.consultancyToTechnologyCompanies}
								multiline
								minRows={4}
								maxRows={7}
							/>

						</div>
						<FormLabel className="textScienticProd formLabel">Descreva os principais prêmios explicitando a importância de cada um:</FormLabel>
						<div className='div-discoveries'>
							<TextInput
								fullWidth
								id="mainAwardsWithImportance"
								name="mainAwardsWithImportance"
								type="mainAwardsWithImportance"
								value={values.mainAwardsWithImportance}
								onChange={(e: any) => setValues({ ...values, mainAwardsWithImportance: e.target.value })}
								error={touched.mainAwardsWithImportance && Boolean(errors.mainAwardsWithImportance)}
								helperText={touched.mainAwardsWithImportance && errors.mainAwardsWithImportance}
								multiline
								minRows={4}
								maxRows={7}
							/>

						</div>
						<FormLabel className="textScienticProd formLabel">Descreva sua atuação na formação de recursos humanos que produziram impactos em inovação:</FormLabel>
						<div className='div-discoveries'>
							<TextInput
								fullWidth
								id="trainingHumanResourcesImpactedInnovation"
								name="trainingHumanResourcesImpactedInnovation"
								type="trainingHumanResourcesImpactedInnovation"
								value={values.trainingHumanResourcesImpactedInnovation}
								onChange={(e: any) => setValues({ ...values, trainingHumanResourcesImpactedInnovation: e.target.value })}
								error={touched.trainingHumanResourcesImpactedInnovation && Boolean(errors.trainingHumanResourcesImpactedInnovation)}
								helperText={touched.trainingHumanResourcesImpactedInnovation && errors.trainingHumanResourcesImpactedInnovation}
								multiline
								minRows={4}
								maxRows={7}
							/>

						</div>
						<FormLabel className="textScienticProd formLabel">Descreva a sua familiaridade no uso e aplicação de tecnologias modernas (exemplos: transformação digital, inteligência artificial, nanotecnologia, internet das coisas, entre outros).</FormLabel>
						<div className='div-discoveries'>
							<TextInput
								fullWidth
								id="familiarityWithModernTechnologies"
								name="familiarityWithModernTechnologies"
								value={values.familiarityWithModernTechnologies}
								onChange={(e: any) => setValues({ ...values, familiarityWithModernTechnologies: e.target.value })}
								error={touched.familiarityWithModernTechnologies && Boolean(errors.familiarityWithModernTechnologies)}
								helperText={touched.familiarityWithModernTechnologies && errors.familiarityWithModernTechnologies}
								multiline
								minRows={4}
								maxRows={7}
							/>

						</div>
						<FormLabel className="textScienticProd formLabel">Descreva suas publicações técnico-científicas mais relevantes (textos, relatórios industriais, etc.):</FormLabel>
						<div className='div-discoveries'>
							<TextInput
								fullWidth
								id="mostRelevantTechnicalScientificPublication"
								name="mostRelevantTechnicalScientificPublication"
								type="mostRelevantTechnicalScientificPublication"
								value={values.mostRelevantTechnicalScientificPublication}
								onChange={(e: any) => setValues({ ...values, mostRelevantTechnicalScientificPublication: e.target.value })}
								error={touched.mostRelevantTechnicalScientificPublication && Boolean(errors.mostRelevantTechnicalScientificPublication)}
								helperText={touched.mostRelevantTechnicalScientificPublication && errors.mostRelevantTechnicalScientificPublication}
								multiline
								minRows={4}
								maxRows={7}
							/>

						</div>
						<FormLabel className="textScienticProd formLabel">Outras informações relevantes:</FormLabel>
						<div className='div-discoveries'>
							<TextInput
								fullWidth
								id="otherRelevantInformation"
								name="otherRelevantInformation"
								type="otherRelevantInformation"
								value={values.otherRelevantInformation}
								onChange={(e: any) => setValues({ ...values, otherRelevantInformation: e.target.value })}
								error={touched.otherRelevantInformation && Boolean(errors.otherRelevantInformation)}
								helperText={touched.otherRelevantInformation && errors.otherRelevantInformation}
								multiline
								minRows={4}
								maxRows={7}
							/>
						</div>

						<FieldArray name="recommendationLetters">
							{({ insert, remove, push }) => (
								<div>
									{
										values.recommendationLetters.length > 0 &&
										values.recommendationLetters.map((recommendationLetter: any, index: any) => {

											return (
												<InputFile
													label={`Carta de recomendação (${index + 1}):*`}
													name={`recommendationLetters.${index}`}
													value={recommendationLetter}
													onChange={(e: any) => setValues(
														{
															...values, recommendationLetters: values.recommendationLetters.map(
																(recLetter: string, index2: number) => index === index2 ? e.target.value : recLetter)
														}
													)}
												// error={touched.recommendationLetters && errors.recommendationLetters?.length && Boolean(errors.recommendationLetters[index])}
												// helperText={touched.recommendationLetters && errors.recommendationLetters?.length && errors.recommendationLetters[index]}
												/>
											)
										})
									}

									{values.recommendationLetters.length === 25 && <div>
										<br />
										<p>Caso possua mais cartas de recomendação <strong>entre em contato</strong> com o suporte do Prêmio CBMM.</p>
										<br />
										<br />
									</div>}

									{values.recommendationLetters.length < 25 && <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10 }}>
										<Button
											type="outline"
											label="Adicionar mais"
											color={baseColors.darkBlue}
											typeSubmit={false}
											onClick={() => push('')}
										/>
									</div>}
								</div>
							)}
						</FieldArray>

						{valid2 &&
							<p className='error-extradados'>É necessário enviar um arquivo para continuar</p>
						}
						
						<InputFile
							label="Currículo Vitae Lattes:*"
							name="curriculum"
							value={values.curriculum}
							onChange={(e: any) => setValues({ ...values, curriculum: e.target.value })}
						// error={touched.curriculum && Boolean(errors.curriculum)}
						// helperText={touched.curriculum && errors.curriculum}
						/>

						{valid3 &&
							<p className='error-extradados'>É necessário enviar um arquivo para continuar</p>
						}


						<div className='row-gap last'>
							<ButtonStages
								type="outline"
								label="SALVAR E SAIR"
								color={baseColors.lightIndigo}
								onClick={() => {
									setTimeout(() => {
										userContext?.signOutUser()
									}, 200);
								}}
							/>

							<ButtonStagesBack
								type="outline"
								label="VOLTAR"
								typeSubmit
								color={baseColors.lightIndigo}
								onClick={() => navigate(-1)}
							/>


							<Button
								style={{
									cursor: 'pointer',
									padding: '10px 50px',
									fontWeight: 400,
									fontSize: 16,
									fontFamily: "Open Sans",
									textAlign: 'center',
									marginRight: 40,

								}}

								label="AVANÇAR"
								color={baseColors.lightIndigo} />

							{/* <Button
						label="Salvar e avançar"
						color={baseColors.darkBlue}
					// style={{marginTop: formik.touched.acceptTerms && Boolean(formik.errors.acceptTerms) ? 0 : 40}}
					/> */}
						</div>

						<ConfirmPopupBack />
						<ConfirmPopupSaveback />

					</Form>
				)}
			</Formik>}
		</div>
	)
}
