

import { MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { IMaskMixin } from "react-imask";
import { baseColors } from "../../styles";
import { Button } from "../Button";
import { TextFieldProps } from '@mui/material/TextField'

import './styles.scss'

interface EditableProps {
    label: string,
    value: string,
    select?: string[],
    onChange: (newValue: string) => void;
    mask?: string;
    definitions?: {
        [key: string]: RegExp
    }
}

export function EditableTextInputCnpj({ label, value, select, onChange, mask, definitions }: EditableProps) {

    const [showEditable, setShowEditable] = useState(false)
    const [actualValue, setActualValue] = useState(value)


    function handleSubmit() {
        setShowEditable(false)
        onChange(actualValue)
    }

    useEffect(() => {
        setActualValue(value)
    }, [value])

    return (
        showEditable ? (
            <div className="personalDataEditRow">
                <p>


                    {select ? (
                        <TextField
                            fullWidth
                            value={actualValue}
                            onChange={e => setActualValue(e.target.value)}
                            variant="standard"
                            select
                        >
                            {select.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : (
                        mask ? (
                            <TextInputMask
                                fullWidth
                                value={actualValue}
                                onChange={(e: any) => setActualValue(e.target.value)}
                                variant="outlined"
                                mask="##.###.###/####-##" // 42.698.503/0001-22
                                definitions={{
                                    '#': /[0-9]/,
                                }}
                            />
                        ) : (
                            <TextField
                                fullWidth
                                value={actualValue}
                                onChange={e => setActualValue(e.target.value)}
                                variant="standard"
                            />
                        )
                    )}
                </p>

                <Button
                    label="Salvar"
                    color={baseColors.darkBlue}
                    style={{ marginLeft: 12 }}
                    type="outline"
                    onClick={handleSubmit}
                />
            </div>
        ) : (
            <div className="personalDataEditRow edit">
                <p>
                    {/* <strong>{`${label}:  `}</strong> */}
                    {value}
                </p>
                <Button
                    label="Editar"
                    color={baseColors.darkBlue}
                    style={{ marginLeft: 20 }}
                    type="outline"
                    onClick={() => setShowEditable(true)}
                />
            </div>
        )
    )
}



interface TextFieldInterface {
    onChange: any;
    mask?: string;
    definitions?: {
        [key: string]: RegExp
    }
}

export const TextInputMask = IMaskMixin(({ value, onChange, mask, definitions, ...rest }: TextFieldProps & TextFieldInterface) => {

    return <TextField
        fullWidth
        value={value}
        onChange={onChange}
        variant="standard"
        {...rest}
    />
});
