
import { useState } from 'react';
import closeIcon from './close_icon.png';

import './styles.scss'

export function ConfirmPopupInst() {

    const [show, setShow] = useState(true)

    return show ? (
        <div className="ConfirmPopupInst">
            <h3>
                Em qualquer etapa, você pode
                <br />
                Salvar, sair da página e voltar a
                <br />
                    preencher em outro momento.
            </h3>

            <button>
                <img src={closeIcon} onClick={() => setShow(false)} alt="Botão de fechar" />
            </button>

        </div>
    ) : null
}
