

export const routesNames = {
    home: {
        path: '/',
        pageTitle: 'Cadastre-se Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Inscrição para o prêmio CBMM',
        pageH2: '',
        pageId: 'home',
    },
    login: {
        path: '/login',
        pageTitle: 'Entre e termine sua inscrição no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Entre com sua conta para continuar',
        pageH2: '',
        pageId: 'login',
    },
    indication: {
        path: '/cadastrar-instituicao',
        pageTitle: 'Cadastre sua instituição no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Indicações para o Prêmio CBMM de Ciência e Tecnologia',
        pageH2: '',
        pageId: 'indicationCreate',
    },
    indicationLogin: {
        path: '/entrar-instituicao',
        pageTitle: 'Entre sua instituição no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Inscrição para o prêmio CBMM de ciência e tecnologia',
        pageH2: '',
        pageId: 'indicationLogin',
    },
    InstitutionIndication: {
        path: '/indique',
        pageTitle: 'Indique para o Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Formulário de indicação',
        pageH2: '',
        pageId: 'InstitutionIndication',
    },
    loginAdmin: {
        path: '/entrar-admin',
        pageTitle: 'Entre sua instituição no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Inscrição para o prêmio CBMM de ciência e tecnologia',
        pageH2: '',
        pageId: 'loginAdmin',
    },
    adminPage: {
        path: '/admin/inscricoes',
        pageTitle: 'Entre sua instituição no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Inscrição para o prêmio CBMM de ciência e tecnologia',
        pageH2: '',
        pageId: 'adminPage',
    },
    adminInstitution: {
        path: '/admin/instituicoes',
        pageTitle: 'Entre sua instituição no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Inscrição para o prêmio CBMM de ciência e tecnologia',
        pageH2: '',
        pageId: 'adminInstitution',
    },
    AdminInsc2024: {
        path: '/admin/inscricoes2024',
        pageTitle: '',
        pageH1: 'Inscrição para o prêmio CBMM de ciência e tecnologia',
        pageH2: '',
        pageId: 'adminIns2024',
    },
    AdminInsc10052024: {
        path: '/admin/inscricoes1005',
        pageTitle: '',
        pageH1: 'Inscrição para o prêmio CBMM de ciência e tecnologia',
        pageH2: '',
        pageId: 'adminIns1005',
    },
    AdminInsti2024: {
        path: '/admin/instituicoes2024',
        pageTitle: '',
        pageH1: 'Inscrição para o prêmio CBMM de ciência e tecnologia',
        pageH2: '',
        pageId: 'AdminInsti2024',
    },
    confirmEmail: {
        path: '/confirme-seu-email',
        pageTitle: 'Confirme seu e-mail e termine sua inscrição no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Confirme seu e-mail para continuar',
        pageH2: '',
        pageId: 'confirmEmail',
    },
    previousRegistrationData: {
        path: '/dados-de-inscricoes-anteriores',
        pageTitle: 'Usar dados de inscrições anteriores no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Confirme seu e-mail para continuar',
        pageH2: '',
        pageId: 'previousRegistrationData',
    },
    technologyPersonalData: {
        path: '/tecnologia/dados-pessoais',
        pageTitle: 'Preencha seus dados pessoais - inscrição no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Dados pessoais',
        pageH2: 'Dados pessoais',
        pageId: 'personalData',
    },
    technologicalContributions: {
        path: '/tecnologia/contribuicoes-tecnologicas',
        pageTitle: 'Preencha suas contribuições tecnológicas - inscrição no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Contribuições tecnológicas',
        pageH2: 'Contribuições tecnológicas',
        pageId: 'technologicalContributions',
    },
    technologicalContributions2: {
        path: '/tecnologia/contribuicoes-tecnologicas-2',
        pageTitle: 'Preencha suas contribuições tecnológicas - inscrição no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Contribuições tecnológicas (continuação)',
        pageH2: 'Contribuições tecnológicas (continuação)',
        pageId: 'technologicalContributions2',
    },
    additionalInformation: {
        path: '/tecnologia/informacoes-complementares',
        pageTitle: 'Informações adicionais - inscrição no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Informações complementares',
        pageH2: 'Informações complementares',
        pageId: 'additionalInformation',
    },
    technologyRevision: {
        path: '/tecnologia/revisao',
        pageTitle: 'Revise sua inscrição no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Revisão',
        pageH2: 'Informações complementares',
        pageId: 'technologyRevision',
    },
    sciencePersonalData: {
        path: '/ciencia/dados-pessoais',
        pageTitle: 'Preencha seus dados pessoais - inscrição no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Dados pessoais',
        pageH2: 'Dados pessoais',
        pageId: 'personalData',
    },
    scientificProduction: {
        path: '/ciencia/producao-cientifica',
        pageTitle: 'Preencha suas contribuições científicas mais relevantes - inscrição no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Produção científica',
        pageH2: 'Contribuições científicas mais relevantes',
        pageId: 'scientificProduction',
    },
    evidenceOfRecognition: {
        path: '/ciencia/evidencias-de-reconhecimento',
        pageTitle: 'Preencha suas evidências de reconhecimento - inscrição no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Evidências de reconhecimento',
        pageH2: 'Evidências que demonstram reconhecimento pela comunidade brasileira e internacional',
        pageId: 'evidenceOfRecognition',
    },
    bibliometricInformation: {
        path: '/ciencia/informacoes-bibliometricas',
        pageTitle: 'Informações bibliométricas - inscrição no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Informações bibliométricas',
        pageH2: 'Informações bibliométricas',
        pageId: 'bibliometricInformation',
    },
    extraData: {
        path: '/ciencia/dados-extras',
        pageTitle: 'Dados extras - inscrição no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Dados Extras',
        pageH2: 'Apêndice',
        pageId: 'extraData',
    },
    scienceRevision: {
        path: '/ciencia/revisao',
        pageTitle: 'Revise sua inscrição no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Revisão',
        pageH2: '',
        pageId: 'technologyRevision',
    },
    thankYou: {
        path: '/obrigado',
        pageTitle: 'Obrigado por se inscrever no Prêmio CBMM de Ciência e Tecnologia',
        pageH1: 'Inscrição para o prêmio CBMM',
        pageH2: '',
        pageId: 'thankYou',
    },
    thankYouIndication: {
        path: '/obrigado-pela-indicacao',
        pageTitle: 'Muito obrigado!',
        pageSubTitle: 'A sua indicação foi submetida com sucesso!',
        pageH1: 'Você receberá uma confirmação ',
        pageH2: 'no e-mail cadastrado.',
        pageId: 'ThankYouIndication',
    },
}
