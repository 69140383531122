
import { default as MaterialButton } from '@mui/material/Button'
import { baseColors } from '../../styles'

import './buttonStyles.css'

interface ButtonProps {
    label: string
    color?: string
    type?: string
    onClick?: any
    typeSubmit?: boolean
}


export function ButtonStagesBack({ label, typeSubmit = true, color = baseColors.darkBlue, type, onClick }: ButtonProps) {

    const defaultStyle = { backgroundColor: color, borderRadius: 20 }

    return (
        <button

            type={typeSubmit ? 'submit' : 'button'} 
            className='custom-button-back'
            onClick={onClick && onClick}
        >
            {label}
        </button>
    )
}

