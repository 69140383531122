import { createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { doc, serverTimestamp, getDoc, updateDoc, query, where, getDocs, collection, deleteDoc, setDoc, limit  } from "firebase/firestore";

import { auth, firestoreDatabase} from '../../services/firebase'

// import { Storage } from './Storage';

const InstitutionCollection =  'institution'
const indicationsCollection =  'indications'


interface createInstitutionData {
    email: string;
    password: string;
}

interface updateInstitutionData {
    uid: string;
    newPhoto: string;
}


export function Institution() {

    async function create({ email, password } : createInstitutionData) {
        try {
            const userCreated = await createUserWithEmailAndPassword(auth, email, password)
            
            const startUserData = {
                completed: false,
            }

            await setDoc(doc(firestoreDatabase, InstitutionCollection, userCreated.user.uid), {
                uid: userCreated.user.uid,
                email: userCreated.user.email, ...startUserData,
                type: 'institution',
                createdAt: serverTimestamp(), updatedAt: serverTimestamp(), loginAt: serverTimestamp(),
            })

            return {
                uid: userCreated.user.uid,
                email: userCreated.user.email,
                type: 'institution',
                password, ...startUserData,
            }
        } catch (error : any) {
            // console.log('ERROR on User - create: ', error, error.message)
            throw new Error(error)
        }
    }

    async function createIndicated({ docId, ...rest } : any) {
        console.log('createIndicated =:> ', { docId, ...rest })
            
        try {
            
            await setDoc(doc(firestoreDatabase, indicationsCollection, docId), {
                ...rest,
                createdAt: serverTimestamp(), updatedAt: serverTimestamp(), loginAt: serverTimestamp(),
            })

            return {
                ...rest,
            }
        } catch (error : any) {
            // console.log('ERROR on User - create: ', error, error.message)
            throw new Error(error)
        }
    }

    async function getData(userUid: string) {
        try {
            const docSnap = await getDoc(doc(firestoreDatabase, InstitutionCollection, userUid))
            if (docSnap.exists()) {
                return {...docSnap.data(), uid: docSnap.id}
            } else {
                return {}
            }
        } catch (error) {
            // console.log('ERROR on User - getData: ', error)
            return 'Error!'
        }
    }

    async function getUsersDataByEmail(email: string) {
        try {

            const docsSnap = await getDocs(
                query(
                    collection(firestoreDatabase, InstitutionCollection),
                    where('email', '==', email),
                )
            )

            const data = docsSnap.docs.map(doc => doc.data().type)
            return data;

        } catch (error) {
            console.log('ERROR on User - getUsersFromCompanyId: ', error)
            return 'Error!'
        }
    }

    async function getIndications(institutionId: string) {
        try {

            const docsSnap = await getDocs(
                query(
                    collection(firestoreDatabase, indicationsCollection), 
                    where('institutionId', '==', institutionId),
                    where('completed', '==', true),
                    // limit(2)
                )
            )
            
            const data = docsSnap.docs.reduce((acc, doc, currentIndex) => {

                const docData = doc.data()
                delete docData.institutionId;

                return {
                    ...acc,
                    ...docData,
                    _total: currentIndex + 1
                }
            }, {_total: 0})

            return data
        } catch (error) {
            // console.log('ERROR on User - getUsersFromCompanyId: ', error)
            return {
                _total: 0
            }
        }
    }

    async function getAllUserDataForReport() {
        try {

            const docsSnap = await getDocs(
                query(
                    collection(firestoreDatabase, InstitutionCollection)
                )
            )
            
            const data = docsSnap.docs.map(doc => {

                const userData = doc.data()

                // Email	Nome	Catgoria	Última etapa enviada	Terminou?	Data último login	Data de cadastro

                const lastStep = userData.category === 'Tecnologia' ? (
                    userData.additionalInformation ? 'Informações adicionais' : 
                    userData.technologicalContributions2 ? 'Contribuições tecnológicas parte 2' :
                    userData.technologicalContributions ? 'Contribuições tecnológicas' :
                    userData.technologyPersonalData ? 'Dados pessoais' : 'Cadastro inicial'
                    ) : (
                        userData.extraData ? 'Dados adicionais' : 
                        userData.scientificProduction ? 'Produção científica' :
                        userData.bibliometricInformation ? 'dados Bibliométricos' :
                        userData.evidenceOfRecognition ? 'Evidências de reconhecimento' :
                        userData.sciencePersonalData ? 'Dados pessoais' : 'Cadastro inicial'
                )

                const dateString = (item:any) => typeof item === 'number' 
                    ? new Date(item).toISOString() 
                    : new Date(item.seconds * 1000).toISOString() 

                return {
                    email: userData.email,
                    name: userData.name,
                    category: userData.category,
                    lastStep: userData.completed ? 'Revisão' : lastStep,
                    completed: userData.completed,
                    loginAt: dateString(userData.loginAt),
                    createdAt: dateString(userData.createdAt),
                }
            })

            return data
        } catch (error) {
            // console.log('ERROR on User - getUsersFromCompanyId: ', error)
            return 'Error!'
        }
    }


    async function login(email: string, password: string) {
        try {
            const resp = await signInWithEmailAndPassword(auth, email, password)
            return resp.user.uid
        } catch (error : any) {
            // console.log('ERROR on User - login: ', error)
            throw new Error(error)
        }
    }

    async function logout() {
        try {
            return await signOut(auth)
        } catch (error) {
            // console.log('ERROR on User - logout: ', error)
            return 'Error!'
        }
    }

    async function update({ uid, ...rest } : updateInstitutionData) {
        if (!uid) return null

        if (!uid.trim()) {
            return null
        }

        try {
            const dataToUpdate = {...rest} as any

            await updateDoc(doc(firestoreDatabase, InstitutionCollection, uid), {
                ...dataToUpdate, updatedAt: serverTimestamp()
            })

            return {
                ...rest, ...dataToUpdate, updatedAt: new Date().getTime(), uid
            }
        } catch (error : any) {
            // console.log('ERROR on User - update: ', error, error.message)
            throw new Error(error)
        }
    }

    async function sendPasswordReset(email: string) {
        await sendPasswordResetEmail(auth, email)
    }

    async function sendConfirmEmail() {
        if (auth.currentUser) {
            await new Promise<any>((resolve, reject) => {setTimeout(resolve, 400)})
            await sendEmailVerification(auth.currentUser)
        }
    }

    return {
       update, getData, create, login, logout, sendPasswordReset, getIndications, sendConfirmEmail, getAllUserDataForReport, createIndicated, getUsersDataByEmail
    }
}
