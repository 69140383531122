
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { firebaseStorage } from "../../services/firebase";

// import { Storage } from './Storage';

const UsersCollection =  'uploads'

export function Storage() {
    
    async function uploadFile(fileToUpload: any, path: string) {
        try {
            const storageRef = ref(firebaseStorage, `${UsersCollection}/${path}`)
    
            await uploadBytes(storageRef, fileToUpload)
            
            return await getDownloadURL(storageRef)
        } catch (error) {
            console.log('ERROR on Storage - uploadPhoto: ', error)
            return 'Error'
        }
    }


    return {
        uploadFile,
    }
}

