
import { FormLabel } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { Button } from '../../../components/Button'
import { ButtonStages } from '../../../components/ButtonStages'
import { ButtonStagesBack } from '../../../components/ButtonStagesBack'
import { TextInput } from '../../../components/TextInput'
import { useAuth } from '../../../hooks/useAuth'
import { routesNames } from '../../../routes/routesNames'
import { baseColors } from '../../../styles'


import './styles.scss'
import { ConfirmPopupBack } from '../../../components/ConfirmPopupBack'
import { ConfirmPopupSaveback } from '../../../components/ConfirmPopupSaveback'


export function BibliometricInformation() {
	const navigate = useNavigate();
	const userContext = useAuth()

	const formik = useFormik({
		initialValues: {
			scientificArticlesPublishedIndexedByScopus: '',
			totalCitations: '',
			totalScientificInitiationStudentsSupervised: '',
			hIndexScopusScore: '',
			leadershipIndex: '',
		},
		onSubmit: (values) => {

			userContext.updateUser({
				bibliometricInformation1: values
			})

			navigate(routesNames.extraData.path)
		},
	})


	useEffect(() => {
		if (userContext?.user?.bibliometricInformation1) {
			Object.keys(userContext?.user?.bibliometricInformation1 || {}).forEach(key => {
				formik.setFieldValue(key, userContext?.user?.bibliometricInformation1[key])
			})
		}else if(userContext?.user?.bibliometricInformation){
			Object.keys(userContext?.user?.bibliometricInformation || {}).forEach(key => {
				formik.setFieldValue(key, userContext?.user?.bibliometricInformation[key])
			})
		}
	}, [userContext?.user?.bibliometricInformation])


	return (
		<div className='formContainerCiencia'>
			<h2 className='textDadospessoais'>Informe sobre suas informações bibliométricas</h2>

			<form onSubmit={formik.handleSubmit}>

				{/* <Button
					label="Salvar e sair"
					type="outline"
					style={{position: 'absolute', top: 0, right: 0}}
					onClick={() => {
						setTimeout(() => {
							userContext?.signOutUser()
						}, 200);
					}}
				/> */}

				<FormLabel className="textScienticProd formLabel">
					Número de artigos científicos publicados em periódicos com<br /> peer review indexados pelo Scopus:
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="scientificArticlesPublishedIndexedByScopus"
						name="scientificArticlesPublishedIndexedByScopus"
						value={formik.values.scientificArticlesPublishedIndexedByScopus}
						onChange={formik.handleChange}
						error={formik.touched.scientificArticlesPublishedIndexedByScopus && Boolean(formik.errors.scientificArticlesPublishedIndexedByScopus)}
						helperText={formik.touched.scientificArticlesPublishedIndexedByScopus && formik.errors.scientificArticlesPublishedIndexedByScopus}
						multiline
						minRows={4}
						maxRows={7}
					/>
				</div>

				<FormLabel className="textScienticProd formLabel">Tem quantas citações (Scopus)? (resposta numérica)</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="totalCitations"
						name="totalCitations"
						value={formik.values.totalCitations}
						onChange={formik.handleChange}
						error={formik.touched.totalCitations && Boolean(formik.errors.totalCitations)}
						helperText={formik.touched.totalCitations && formik.errors.totalCitations}
						multiline
					/>
				</div>

				<FormLabel className="textScienticProd formLabel">Quantos alunos de iniciação científica, mestrado, doutorado e pós-doutorado já orientou? (resposta numérica)</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="totalScientificInitiationStudentsSupervised"
						name="totalScientificInitiationStudentsSupervised"
						value={formik.values.totalScientificInitiationStudentsSupervised}
						onChange={formik.handleChange}
						error={formik.touched.totalScientificInitiationStudentsSupervised && Boolean(formik.errors.totalScientificInitiationStudentsSupervised)}
						helperText={formik.touched.totalScientificInitiationStudentsSupervised && formik.errors.totalScientificInitiationStudentsSupervised}
						multiline
					/>
				</div>

				<FormLabel className="textScienticProd formLabel">
					Qual é o seu H-index (Scopus)? Compare com seus pares do Brasil e exterior.
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="hIndexScopusScore"
						name="hIndexScopusScore"
						value={formik.values.hIndexScopusScore}
						onChange={formik.handleChange}
						error={formik.touched.hIndexScopusScore && Boolean(formik.errors.hIndexScopusScore)}
						helperText={formik.touched.hIndexScopusScore && formik.errors.hIndexScopusScore}
						multiline
						minRows={4}
						maxRows={7}
					/>
				</div>

				<FormLabel className="textScienticProd formLabel">
					Qual é o seu Leadership Index (LI)? LI é a razão entre o número de artigos nos quais o seu H-index é maior que dos demais coautores e o número total de (seus) artigos em periódicos indexados pelo Scopus (LI {"<="}1).
				</FormLabel>
				<div className='div-discoveries'>
					<TextInput
						fullWidth
						id="leadershipIndex"
						name="leadershipIndex"
						value={formik.values.leadershipIndex}
						onChange={formik.handleChange}
						error={formik.touched.leadershipIndex && Boolean(formik.errors.leadershipIndex)}
						helperText={formik.touched.leadershipIndex && formik.errors.leadershipIndex}
						multiline
						minRows={4}
						maxRows={7}
					/>

				</div>
				<div className='row-gap last'>
					<ButtonStages
						type="outline"
						label="SALVAR E SAIR"
						color={baseColors.lightIndigo}
						onClick={() => {
							setTimeout(() => {
								userContext?.signOutUser()
							}, 200);
						}}
					/>

					<ButtonStagesBack
						type="outline"
						label="VOLTAR"
						color={baseColors.lightIndigo}
						typeSubmit
						onClick={() => navigate(-1)}
					// style={{marginTop: formik.touched.acceptTerms && Boolean(formik.errors.acceptTerms) ? 0 : 40}}
					/>

					<Button
						style={{
							cursor: 'pointer',
							padding: '10px 50px',
							fontWeight: 400,
							fontSize: 16,
							fontFamily: "Open Sans",
							textAlign: 'center',
							marginRight: 40,

						}}

						label="AVANÇAR"
						color={baseColors.lightIndigo} />

					{/* <Button
						label="Salvar e avançar"
						color={baseColors.darkBlue}
					// style={{marginTop: formik.touched.acceptTerms && Boolean(formik.errors.acceptTerms) ? 0 : 40}}
					/> */}
				</div>

				<ConfirmPopupBack />
				<ConfirmPopupSaveback />

			</form>

		</div>
	)
}
