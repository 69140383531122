import { useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ProgressHeader } from "../../components/ProgressHeader";
import { useAuth } from "../../hooks/useAuth";
import { routesNames } from "../../routes/routesNames";


export function FormPageLayout({type, headerProgress}: {headerProgress?: boolean, type: 'Ciência' | 'Tecnologia'}) {

    const {pathname} = useLocation();
    const navigate = useNavigate();
	const userContext = useAuth()

    const actualRouteData = useMemo(() => {
        const routeArray = routesNames
        return Object.values(routeArray).find(route => route?.path === pathname)
    }, [pathname])

    useEffect(() => {
        if (!userContext?.user?.uid) {
            navigate(routesNames.home.path)
            return
        }

        if (userContext?.user?.completed === true) {
            navigate(routesNames.thankYou.path)
            return
        }

        if (userContext?.user?.completed2024 === true) {
            navigate(routesNames.thankYou.path)
            return
        }


        if (userContext?.user?.category !== type) {
            if (userContext?.user?.category === 'Ciência') {
                navigate(routesNames.sciencePersonalData.path)
                return
            } else {
                navigate(routesNames.technologyPersonalData.path)
                return
            }
        }
        
    }, [navigate, type, userContext?.user]);


    return (
        <main id="main">

            <section id={actualRouteData?.pageId || ''}>
            <div>
                
    

            </div>
                    <ProgressHeader
                    title={actualRouteData?.pageH2 || ''}
                    page={actualRouteData?.pageH1 || ''}
                    id={actualRouteData?.pageId || ''}
                    headerProgress={headerProgress}
                    type={type}
                />
               

                <Outlet />
            </section>

        </main>
    )
}

