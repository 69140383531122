
import { useState } from 'react';
import closeIcon from './close_icon.png';

import './styles.scss'

export function ConfirmPopup() {

    const [show, setShow] = useState(true)

    return show ? (
        <div className="confirmPopup">
            <h3>
                <strong>Atenção:</strong> Uma vez que o formulário é submetido, não é possível adicionar/modificar nenhuma informação. Revise com cautela. 
                <br />
                <br />
                Ao fechar este pop-up,<strong> você está ciente e concorda</strong> com esta informação.
            </h3>

            <button>
                <img src={closeIcon} onClick={() => setShow(false)} alt="Botão de fechar"/>
            </button>

        </div>
    ) : null
}
