
import { useState } from 'react';
import closeIcon from './close_icon.png';

import './styles.scss'

export function ConfirmPopupSaveback() {

    const [show, setShow] = useState(true)

    return show ? (
        <div className="ConfirmPopupSaveback">
            <h3>
                Você pode salvar, sair da
                <br />
                página e voltar a preencher
                <br />
                em outro momento.
            </h3>

            <button>
                <img src={closeIcon} onClick={() => setShow(false)} alt="Botão de fechar" />
            </button>

        </div>
    ) : null
}
