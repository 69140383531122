
import {default as MaterialButton} from '@mui/material/Button'
import { baseColors } from '../../styles'

interface ButtonProps {
    label: string
    color?: string
    type?: string
    style?: any
    onClick?: any
    typeSubmit?: boolean
}

export function Button({label, typeSubmit = true, color = baseColors.darkBlue, type, style, onClick} : ButtonProps) {

    const defaultStyle = {backgroundColor: color, borderRadius: 40}

    const buttonStyle = Object.assign(
        defaultStyle, 
        style, 
        type === 'outline' ? {backgroundColor: '#fff', borderColor: color, color: color } : {}
    )

    return (
        <MaterialButton
            variant={type === 'outline' ? 'outlined' : 'contained'}
            type={typeSubmit ? 'submit' : 'button'}
            className='btnCiencia'
            style={buttonStyle}
            onClick={onClick && onClick}
        >
            {label}
        </MaterialButton>
    )
}

