
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();
    console.error('error on page load: ', error);

    return (
        <div id="error-page">
            <h1>Página não encontrada.</h1>
            <p>A página que você está tentando acessar não existe ou não está disponível.</p>
        </div>
    );
}
