
import TextField, {TextFieldProps} from '@mui/material/TextField'

import './styles.scss'

interface TextFieldInterface {
    id: string;
    label?: string;
    name: string;
    value: any;
    onChange?: any;
    error?: any;
    helperText?: any;
}

export function TextInput({id, name, label, value, onChange, error, helperText, ...rest} : TextFieldInterface & TextFieldProps) {

    return <TextField
        id={id}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        className="textInput"
        {...rest}
    />

}

