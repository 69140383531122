import { useEffect, useState } from "react";
import { SideBarNavigator } from "../../components/SideBar";
import { Admin } from "../../core/Controllers/Admin";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Dialog, DialogActions, Button, DialogContent, DialogTitle } from "@mui/material";
import { ViewIndicationsData } from "../../components/ViewIndicationsData";


export function AdminInstitution() {

    const [open, setOpen] = useState(false);
    const [rowData, setRowData] = useState<any>([])
    const [data, setData] = useState<any>([])

    useEffect(() => {
        Admin().getInstitutionsData().then((users: any) => setData(users))
    }, [])

    return (
        <>
            <SideBarNavigator />

            <div style={{marginLeft: 220}}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650, maxWidth: 1100 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome do usuário</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Nome da Instituição</TableCell>
                                <TableCell style={{minWidth: 170}}>CNPJ</TableCell>
                                <TableCell align="right">Completou as indicações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row: any) => {





                                let createdAtDate: any;



                                if (row.createdAt.nanoseconds) {

                                    createdAtDate = new Date(row.createdAt.seconds * 1000 + row.createdAt.nanoseconds / 1000000)

                                } else {
                                    createdAtDate = new Date(row.createdAt);
                                }



                                if (createdAtDate.getFullYear() === 2023) {
                                    return (
                                        <TableRow
                                            key={row.uid}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setRowData(row)
                                                setOpen(true)
                                            }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{row.institutionName}</TableCell>
                                            <TableCell>{row.cnpj}</TableCell>
                                            <TableCell align="right">{row.completed ? 'Sim' : 'Não'}</TableCell>
                                        </TableRow>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>




                <Dialog
                    maxWidth={'lg'}
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <DialogTitle>Dados da inscrição</DialogTitle>

                    <DialogContent>
                        {/* <DialogContentText>
                            You can set my maximum width and whether to adapt or not.
                        </DialogContentText> */}

                        <Box
                            noValidate
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                                width: 'fit-content',
                            }}
                        >
                            
                            <ViewIndicationsData data={{...rowData}} />
                        </Box>
                    </DialogContent>

                    <DialogActions style={{gap: 20, marginRight: 30}}>
                        {/* <Button onClick={() => handleEnframe(rowData)} color={!rowData.enframe ? "success" : "error"} variant="contained">{!rowData.enframe ? 'Enquadrar' : 'Retirar enquadro'}</Button> */}
                        <Button onClick={() => setOpen(false)} variant="contained" >Fechar</Button>
                    </DialogActions>
                </Dialog>

            </div>
        </>
    )
}

