
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../components/Button'
import { ButtonYes } from '../../components/ButtonYes'
import { ButtonNo } from '../../components/ButtonNo'  
import { User } from '../../core/Controllers/User'
import { useAuth } from '../../hooks/useAuth'
import { routesNames } from '../../routes/routesNames'

import './styles.scss'


export function PreviousRegistrationData() {

    const userAuth = useAuth()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [lastData, setLastData] = useState({})
    

    const handleSubmit = useCallback(async (useData: boolean) => {
        console.log('handleSubmit :: --== =', useData, userAuth.user.category);

        // TODO:
        // Baixar dados do banco de dados e salvar nesse novo usuário
        // Delay para processamento
        // Redirecionar para rota correta

        if (useData) {
            // utilizar data -> lastData
            userAuth.updateUser({
                ...lastData
            })
            await new Promise<any>((resolve, reject) => {setTimeout(resolve, 400)})
        }

        if (userAuth?.user?.category === 'Tecnologia') {
            navigate(routesNames.technologyPersonalData.path)
        } else {
            navigate(routesNames.sciencePersonalData.path)
        }

    }, [lastData, navigate, userAuth])


    useEffect(() => {
        if (!userAuth?.user?.email) return

        User().getDataFromEmail(userAuth?.user?.email)
            .then((data) => {

                if (!data.length) {
                    setLoading(false)
                    handleSubmit(false)
                    return
                }
                setLoading(false)
                setLastData(data)
            })
    }, [handleSubmit, navigate, userAuth])

    return (
        <div className='previousRegistrationData formContainer'>

            {loading ? (
                <h4>
                    Carregando...
                </h4>
            ) : (
                <>
                    <h4>
                        Encontramos dados de inscrições anteriores.
                    </h4>

                    <p>
                        Você deseja reutilizar parte dos dados de sua última inscrição?
                    </p>

                    <div className='row-gap last'>
                        <Button
                            type="outline"
                            label="Começar a inscrição agora"
                            typeSubmit={false}
                            onClick={() => handleSubmit(false)}
                            style={{marginTop: 40}}
                        />

                        <Button
                            label="Utilizar parte dos dados"
                            style={{marginTop: 40}}
                            onClick={() => handleSubmit(true)}
                        />
                    </div>

                </>
            )}
        </div>
    )
}
